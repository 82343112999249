import React from "react";
import { Grid } from "@mui/material";

import { DatePicker, Input, NumberInput, Select } from "@homesusa/form";
import { AppContext, SubmitButton } from "@homesusa/layout";
import { AuthCompanyContext } from "@homesusa/auth";
import { ListingFilter } from "core/interfaces/filters";
import { getListings } from "core/services/sale-listings.service";

export function ListingSearch({
  onSelect
}: {
  onSelect: (listingId?: string) => void;
}): JSX.Element {
  const [listingFilter, setListingFilter] = React.useState<ListingFilter>({
    take: 50
  });
  const [listings, setListings] = React.useState<Record<string, string>>();
  const { addAlert } = React.useContext(AppContext);
  const { currentMarket } = React.useContext(AuthCompanyContext);

  const handleChange =
    (name: string) =>
    (value: unknown): void => {
      setListingFilter((prev) => ({ ...prev, [name]: value }));
    };

  const searchListings = async (): Promise<void> => {
    setListings(undefined);
    return getListings(currentMarket)(listingFilter).then((response) => {
      if (response.data.length > 0) {
        setListings(
          response.data.reduce(
            (current, listing) => {
              let title = `${listing.streetNum} ${listing.streetName}`;
              title += listing.mlsNumber
                ? ` - [MLS# ${listing.mlsNumber}]`
                : "";
              return { ...current, [listing.id]: title };
            },
            {} as Record<string, string>
          )
        );
      } else {
        addAlert({
          message: "No listings were found with the requested data",
          variant: "error"
        });
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} container alignItems="end">
        <Grid item xs={6}>
          <NumberInput label="SqFt Min" onChange={handleChange("sqftMin")} />
        </Grid>
        <Grid item xs={6}>
          <NumberInput label="SqFt Max" onChange={handleChange("sqftMax")} />
        </Grid>
      </Grid>
      <Grid item xs={12} container alignItems="end">
        <Grid item xs={6}>
          <DatePicker
            label="Start Date"
            onChange={handleChange("creationStartDate")}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="End Date"
            onChange={handleChange("creationEndDate")}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container alignItems="end">
        <Grid item xs={6}>
          <Input
            label="Street Number"
            onChange={handleChange("streetNumber")}
            notAllowedWhiteSpaces="both"
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label="Street Name"
            onChange={handleChange("streetName")}
            notAllowedWhiteSpaces="start"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container alignItems="end">
        <Grid item xs={6}>
          <Input label="MLS Number" onChange={handleChange("mlsNumber")} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <SubmitButton onClick={searchListings}>Search</SubmitButton>
      </Grid>
      {!!listings && (
        <Grid item xs={12}>
          <Select
            fullWidth
            label="Select Listing"
            options={listings}
            onChange={onSelect}
          />
        </Grid>
      )}
    </Grid>
  );
}
