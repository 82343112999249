import React from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import {
  AlertType,
  AlertTypeLabel,
  AlertVariant,
  ImportStatus
} from "modules/dashboard/enums";
import {
  ExportButtons,
  GridProvider,
  GridTable,
  FetchData
} from "@homesusa/grid-table";
import { AnimatedContainer, MarketCode, RoleEmployee } from "@homesusa/core";
import { SearchBar } from "../../search-bar.component";
import { useHasXmlService, useXmlGridColumns } from "modules/dashboard/hooks";
import { useStyles } from "../../section-grid/section-grid.styles";
import { DashboardContext } from "modules/dashboard/context/dashboard";
import { XmlListing } from "modules/dashboard/interfaces/alerts/xml";
import { StockChart } from "../charts/stock-chart/stock-chart.component";
import { AuthCompanyContext, useHasRole } from "@homesusa/auth";
import { AlertSummaryContext, ChartContext } from "modules/dashboard/context";
import { ChartFilters } from "../chart-filters/chart-filters.component";

export const AlertGrid = ({
  isSuggestedComps = false,
  alertType
}: {
  isSuggestedComps?: boolean;
  alertType: AlertType;
}): JSX.Element => {
  const { chartState } = React.useContext(ChartContext);
  const { hasEmployeeRole } = useHasRole();
  const isReadOnly = hasEmployeeRole([RoleEmployee.SalesEmployeeReadonly]);
  const classes = useStyles();
  const columns = useXmlGridColumns(isSuggestedComps);
  const importStatus = ImportStatus.Available;
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const { setCurrentData, currentData } = React.useContext(AlertSummaryContext);

  const {
    xmlServices: { getListings }
  } = React.useContext(DashboardContext);
  const hasXmlService = useHasXmlService();
  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      globalFilter,
      sortBy
    }: FetchData): Promise<void> => {
      try {
        const response = await getListings({
          take: pageSize,
          skip: pageIndex,
          searchBy: globalFilter,
          sortBy: sortBy || undefined,
          importStatus,
          isOnlyCount: false,
          isSuggestedComps: isSuggestedComps
        });
        setCurrentData((prev) =>
          prev ? { ...prev, gridData: response } : undefined
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [getListings]
  );

  const variant = React.useMemo(() => {
    if (currentData?.gridData?.total) {
      return currentData?.gridData?.total > 0
        ? AlertVariant.Danger
        : AlertVariant.Disabled;
    }

    return AlertVariant.Disabled;
  }, [currentData?.gridData.total]);

  const fetchDataForDownloading = async (): Promise<XmlListing[]> => {
    if (!currentData?.gridData || currentData?.gridData.total <= 0) {
      return [];
    }

    const result = await getListings({
      take: currentData?.gridData?.total,
      skip: 0,
      importStatus,
      isSuggestedComps: isSuggestedComps
    });

    return result?.data;
  };
  React.useEffect(() => {
    setCurrentData((prev) =>
      prev?.alertType != alertType
        ? {
            columns,
            alertType: alertType,
            emptyVariant: AlertVariant.Disabled,
            populatedVariant: AlertVariant.Disabled,
            gridData: {
              data: [],
              total: 0
            }
          }
        : prev
    );
  }, []);
  if (!hasXmlService) {
    return (
      <>
        {isSuggestedComps ? (
          <></>
        ) : (
          <Paper elevation={3} sx={classes.root}>
            {currentMarket != MarketCode.CTX ? (
              <AnimatedContainer sx={classes.successContainer}>
                <img src="https://homesusastorage.blob.core.windows.net/webimages/dashboard_success_image.png" />
                {"You're all set. There's nothing left to do."}
              </AnimatedContainer>
            ) : (
              <>
                <ChartFilters />
                <StockChart
                  data={
                    chartState.listingStatus ?? [
                      { name: "Active", dataPoints: [] },
                      { name: "Pending", dataPoints: [] },
                      { name: "Sales", dataPoints: [] }
                    ]
                  }
                />
              </>
            )}
          </Paper>
        )}
      </>
    );
  }

  return (
    <Paper elevation={2} sx={classes.alertGrid}>
      <Box sx={classes.header}>
        <Typography variant="h3" sx={classes.title} className={variant}>
          {AlertTypeLabel.get(alertType)}
        </Typography>
        {variant !== AlertVariant.Disabled && (
          <Box sx={classes.notificationBox} className={variant}>
            {currentData?.gridData?.total + " actions required"}
          </Box>
        )}
      </Box>
      <Box sx={classes.table}>
        <GridProvider
          options={{
            columns,
            data: currentData?.gridData?.data ?? [],
            showCellTooltip: true
          }}
          pageSize={4}
          fetchData={fetchData}
          totalRows={currentData?.gridData?.total}
        >
          <Stack direction="row" spacing={1}>
            {!isReadOnly && (
              <ExportButtons
                onClick={fetchDataForDownloading}
                fileName={AlertTypeLabel.get(alertType) || ""}
              />
            )}
            <SearchBar sx={classes.searchBar} />
          </Stack>
          <GridTable sx={classes.tableLayout} />
        </GridProvider>
      </Box>
    </Paper>
  );
};
