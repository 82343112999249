export const orderRooms = <TRoom extends { roomType: string }>(
  rooms: TRoom[]
): TRoom[] => {
  const priorityRooms = [
    "entry",
    "living",
    "family",
    "game",
    "media",
    "dining",
    "breakfast",
    "kitchen",
    "study",
    "masterBedroom",
    "bed",
    "masterBath",
    "masterBedroomCloset",
    "utility",
    "other"
  ];
  rooms.sort((a: { roomType: string }, b: { roomType: string }): number => {
    return priorityRooms.indexOf(a.roomType) < priorityRooms.indexOf(b.roomType)
      ? -1
      : priorityRooms.indexOf(a.roomType) > priorityRooms.indexOf(b.roomType)
        ? 1
        : 0;
  });
  return rooms;
};
