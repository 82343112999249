import { CookiesManager } from "@homesusa/core";
import { GaugeTotal } from "../interfaces";
import { todayIntheLastTimeOfTheDay } from "./date-getters";

const setTotalGauge = (total: number, name: string): void => {
  const gauge: GaugeTotal = {
    total,
    expirationDate: todayIntheLastTimeOfTheDay()
  };
  CookiesManager.setCookie<GaugeTotal>(name, gauge);
};

const deleteTotalGauge = (name: string): void => {
  CookiesManager.deleteCookie(name);
};

export const getTotalGauge = (name: string): GaugeTotal => {
  const total = CookiesManager.getCookie<GaugeTotal | undefined>(name);
  return total ? total : { total: 0, expirationDate: new Date() };
};

export const updateTotalGauge = (total: number, name: string): void => {
  const gauge = getTotalGauge(name);
  const canUpdate = gauge.expirationDate < new Date() || gauge.total < total;

  if (gauge.total === 0) setTotalGauge(total, name);
  else if (canUpdate) {
    deleteTotalGauge(name);
    setTotalGauge(total, name);
  }
};

export const initializeGaugeTotal = (
  total: number,
  name: string
): GaugeTotal => {
  updateTotalGauge(total, name);
  return getTotalGauge(name);
};
