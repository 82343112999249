export enum SurveillanceEquipment {
  Unknown = "unknown",
  Yes = "yes",
  No = "no"
}
export const SurveillanceEquipmentLabel = new Map<
  SurveillanceEquipment,
  string
>([
  [SurveillanceEquipment.Unknown, "Unknown"],
  [SurveillanceEquipment.Yes, "Yes"],
  [SurveillanceEquipment.No, "No"]
]);
