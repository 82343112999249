import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { FormTabContent } from "@homesusa/form";
import { NotesManager } from "@homesusa/notes";

const CommunityDetails = React.lazy(
  () => import("app/pages/community-sale-details.component")
);
const CommunityGrid = React.lazy(
  () => import("app/pages/community-sale-grid.component")
);
const PhotoRequets = React.lazy(
  () =>
    import(
      "modules/communities/components/photo-requests/photo-requets.component"
    )
);
const CommunityEmployee = React.lazy(
  () =>
    import(
      "modules/communities/components/employees/community-employee.component"
    )
);
const ListingGrid = React.lazy(
  () => import("modules/communities/components/listing-grid.component")
);

const CommunitySavedChanges = React.lazy(
  () =>
    import(
      "modules/communities/components/community-saved-changes/community-saved-changes.component"
    )
);
const CommunitySavedChangesDetails = React.lazy(
  () => import("app/pages/community-saved-changes-details.component")
);

export function CommunityRoutes(): JSX.Element {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route index element={<CommunityGrid />} />
        <Route
          path=":id"
          element={
            <Suspense fallback={<LinearProgress />}>
              <CommunityDetails />
            </Suspense>
          }
        >
          <Route path="notes" element={<NotesManager />} />
          <Route path="listings" element={<ListingGrid />} />
          <Route path="photo-requests" element={<PhotoRequets />} />
          <Route path="employees" element={<CommunityEmployee />} />
          <Route
            path="community-saved-changes"
            element={<CommunitySavedChanges />}
          />
          <Route path="lots" element={<ListingGrid />} />
          <Route index element={<FormTabContent />} />
        </Route>
        <Route
          path=":id/community-saved-changes/:historyId"
          element={<CommunitySavedChangesDetails />}
        />
      </Routes>
    </Suspense>
  );
}
