export enum LegalSubdivision {
  LagoMar = "lagoMar",
  AlcornBend = "alcornBend",
  AldenWoods = "aldenWoods",
  AlderTrails = "alderTrails",
  Aliana = "aliana",
  ArborLake = "arborLake",
  AuburnManorAtRiverstone = "auburnManorAtRiverstone",
  AudubonPark = "audubonPark",
  AugustaPines = "augustaPines",
  AustinMeadow = "austinMeadow",
  AustinPark = "austinPark",
  AustinParkCourtyardHomes = "austinParkCourtyardHomes",
  AutumnRidge = "autumnRidge",
  AvalonGardensOfAvalon = "avalonGardensOfAvalon",
  AvalonMeadowsOfAvalon = "avalonMeadowsOfAvalon",
  AvalonVillagesOfAvalon = "avalonVillagesOfAvalon",
  AvalonWatersOfAvalon = "avalonWatersOfAvalon",
  AvalonAtRiverstone = "avalonAtRiverstone",
  AvalonAtSpringGreen = "avalonAtSpringGreen",
  AvalonEnclaveAtWatersOfAvalon = "avalonEnclaveAtWatersOfAvalon",
  BendersLanding = "bendersLanding",
  BlakelyBend = "blakelyBend",
  BrandonSPoint = "brandonSPoint",
  BrazoriaBendCtryClbEst = "brazoriaBendCtryClbEst",
  BrazosLakes = "brazosLakes",
  BrazosLanding = "brazosLanding",
  BrazosValley = "brazosValley",
  Briarwood = "briarwood",
  Bridgeland = "bridgeland",
  BridgelandLakelandHeights = "bridgelandLakelandHeights",
  Bridgewater = "bridgewater",
  BrooksideAtRiverstone = "brooksideAtRiverstone",
  CambridgePark = "cambridgePark",
  CaneIsland = "caneIsland",
  CanyonLakesWest = "canyonLakesWest",
  Chimneystone = "chimneystone",
  CincoRanchNorthwest = "cincoRanchNorthwest",
  CincoRanchSouthwest = "cincoRanchSouthwest",
  CincoRanchWest = "cincoRanchWest",
  CityPark = "cityPark",
  CityPlaza = "cityPlaza",
  ClaytonsBend = "claytonsBend",
  ColonyBend = "colonyBend",
  ColonyCove = "colonyCove",
  ColonyCreek = "colonyCreek",
  ColonyGrant = "colonyGrant",
  ColonyMeadows = "colonyMeadows",
  ColonyOaks = "colonyOaks",
  ColonyPark = "colonyPark",
  ColonyWoods = "colonyWoods",
  CommonwealthEstates = "commonwealthEstates",
  CommonwealthPark = "commonwealthPark",
  CreeksidePlace = "creeksidePlace",
  CreekstoneVillageAtRiverstone = "creekstoneVillageAtRiverstone",
  CrescentLakes = "crescentLakes",
  CrescentsOnTheGreen = "crescentsOnTheGreen",
  CrossCreekRanch = "crossCreekRanch",
  CrossingCoveAtRiverstone = "crossingCoveAtRiverstone",
  CypressCreekLakes = "cypressCreekLakes",
  Edgewater = "edgewater",
  EdgewoodAtRiverstone = "edgewoodAtRiverstone",
  Elyson = "elyson",
  Firethorne = "firethorne",
  FirstColonyMud5 = "firstColonyMud5",
  FostersGreen = "fostersGreen",
  Fulbrook = "fulbrook",
  FulbrookCreeks = "fulbrookCreeks",
  Fulshear = "fulshear",
  GrandCayHarbour = "grandCayHarbour",
  GrandMissionEstates = "grandMissionEstates",
  GrandVista = "grandVista",
  GrantsLake = "grantsLake",
  GrantsLakePatioHomes = "grantsLakePatioHomes",
  GrantsLakeTempos = "grantsLakeTempos",
  GreatwoodArbor = "greatwoodArbor",
  GreatwoodB170 = "greatwoodB170",
  GreatwoodBend = "greatwoodBend",
  GreatwoodBrooksMill = "greatwoodBrooksMill",
  GreatwoodCharlestonEstates = "greatwoodCharlestonEstates",
  GreatwoodCrossing = "greatwoodCrossing",
  GreatwoodEstates = "greatwoodEstates",
  GreatwoodFairview = "greatwoodFairview",
  GreatwoodForest = "greatwoodForest",
  GreatwoodGlen = "greatwoodGlen",
  GreatwoodGreen = "greatwoodGreen",
  GreatwoodHighlandPark = "greatwoodHighlandPark",
  GreatwoodKnoll = "greatwoodKnoll",
  GreatwoodManor = "greatwoodManor",
  GreatwoodShores = "greatwoodShores",
  GreatwoodSpringfield = "greatwoodSpringfield",
  GreatwoodStonebridge = "greatwoodStonebridge",
  GreatwoodT270 = "greatwoodT270",
  GreatwoodTerrace = "greatwoodTerrace",
  GreatwoodTract = "greatwoodTract",
  GreatwoodTrails = "greatwoodTrails",
  GreatwoodTuscanyPlace = "greatwoodTuscanyPlace",
  GreatwoodVillage = "greatwoodVillage",
  GreatwoodWoodCreek = "greatwoodWoodCreek",
  GreatwoodWoodhaven = "greatwoodWoodhaven",
  Harmony = "harmony",
  HarpersPreserve = "harpersPreserve",
  HartfordLandingAtRiverstone = "hartfordLandingAtRiverstone",
  HarringtonTrails = "harringtonTrails",
  HighMeadows = "highMeadows",
  HuntersCreek = "huntersCreek",
  HuntersGlen = "huntersGlen",
  ImperialOaks = "imperialOaks",
  Jordan = "jordan",
  JordanRanch = "jordanRanch",
  JordanSub = "jordanSub",
  Katy = "katy",
  KatyCreekRanch = "katyCreekRanch",
  KensingtonAtRiverstone = "kensingtonAtRiverstone",
  KingwoodAtRoyalBrook = "kingwoodAtRoyalBrook",
  LakePointe = "lakePointe",
  Lakefield = "lakefield",
  LakesAtCreekside = "lakesAtCreekside",
  LakesAtMasonPark = "lakesAtMasonPark",
  LakesOfAustinPark = "lakesOfAustinPark",
  LandingAtGrantsLake = "landingAtGrantsLake",
  LaurelGlen = "laurelGlen",
  LexingtonColony = "lexingtonColony",
  LexingtonMeadows = "lexingtonMeadows",
  LongMeadowFarms = "longMeadowFarms",
  LongPointWoods = "longPointWoods",
  MaranathaFarms = "maranathaFarms",
  Marbella = "marbella",
  MarbleBendAtRiverstone = "marbleBendAtRiverstone",
  MeadowLakes = "meadowLakes",
  Meadowvale = "meadowvale",
  MeridianEstates = "meridianEstates",
  MeridianParkAtRiverstone = "meridianParkAtRiverstone",
  Meridiana = "meridiana",
  MillwoodAtRiverstone = "millwoodAtRiverstone",
  Morningside = "morningside",
  MostynManor = "mostynManor",
  NandinaAtRiverstone = "nandinaAtRiverstone",
  NewTerritory = "newTerritory",
  NewTerritoryParcelSf13 = "newTerritoryParcelSf13",
  NewTerritoryParcelSf2 = "newTerritoryParcelSf2",
  NewTerritoryParcelSf34 = "newTerritoryParcelSf34",
  NewTerritoryParcelSf35 = "newTerritoryParcelSf35",
  NewTerritoryParcelSf37 = "newTerritoryParcelSf37",
  NewTerritoryParcelSp2 = "newTerritoryParcelSp2",
  NewTerritoryParcelSp4 = "newTerritoryParcelSp4",
  NewTerritorySunsetCove = "newTerritorySunsetCove",
  Northgrove = "northgrove",
  Northpointe = "northpointe",
  Oakland = "oakland",
  OaksOfAlcorn = "oaksOfAlcorn",
  OliveHillAtRiverstone = "oliveHillAtRiverstone",
  Other = "other",
  Oxford = "oxford",
  ParkwayTerrace = "parkwayTerrace",
  PointRoyale = "pointRoyale",
  Pomona = "pomona",
  ProvidenceAtRiverstone = "providenceAtRiverstone",
  RiverForest = "riverForest",
  Riverpark = "riverpark",
  RiverparkWest = "riverparkWest",
  RiversEdge = "riversEdge",
  Riverstone = "riverstone",
  RobinsonLanding = "robinsonLanding",
  RoyalOaksCourt = "royalOaksCourt",
  SandersGlenAtRiverstone = "sandersGlenAtRiverstone",
  SandersRidge = "sandersRidge",
  SenovaAtRiverstone = "senovaAtRiverstone",
  SettlersGrove = "settlersGrove",
  SettlersPark = "settlersPark",
  SevenOaks = "sevenOaks",
  SevenOaksTH = "sevenOaksTH",
  ShadowGlenAtRiverstone = "shadowGlenAtRiverstone",
  SherwoodForestGlen = "sherwoodForestGlen",
  SiennaPlantation = "siennaPlantation",
  SilverGroveAtRiverstone = "silverGroveAtRiverstone",
  SilverRanch = "silverRanch",
  SomersetGreen = "somersetGreen",
  Sommerall = "sommerall",
  SommerallPark = "sommerallPark",
  SpencersGlen = "spencersGlen",
  SpringBranchEstates = "springBranchEstates",
  SpringwoodsVillage = "springwoodsVillage",
  StablewoodFarms = "stablewoodFarms",
  StephensGrant = "stephensGrant",
  SterlingCreek = "sterlingCreek",
  Stillwater = "stillwater",
  Stonehaven = "stonehaven",
  SummerLakes = "summerLakes",
  Summerhill = "summerhill",
  SuttonForest = "suttonForest",
  SuttonPark = "suttonPark",
  Sweetbriar = "sweetbriar",
  SweetbriarAtRiverstone = "sweetbriarAtRiverstone",
  Sweetwater = "sweetwater",
  SweetwaterCourtTownhomes = "sweetwaterCourtTownhomes",
  SweetwaterEstates = "sweetwaterEstates",
  SweetwaterTownhomes = "sweetwaterTownhomes",
  Tara = "tara",
  Telfair = "telfair",
  TexanaPlantation = "texanaPlantation",
  TheCityPlazaAtTownSquare = "theCityPlazaAtTownSquare",
  TheCrossingAtRiverstone = "theCrossingAtRiverstone",
  TheEnclaveAtGreatwood = "theEnclaveAtGreatwood",
  TheGroves = "theGroves",
  TheHighlands = "theHighlands",
  TheLakes = "theLakes",
  TheLandingAtGreatwood = "theLandingAtGreatwood",
  TheOrchardAtRiverstone = "theOrchardAtRiverstone",
  TheReserveAtRiverstone = "theReserveAtRiverstone",
  TheTerraceAtRiverstone = "theTerraceAtRiverstone",
  TheTownsOfGrantsLake = "theTownsOfGrantsLake",
  TheWoodlandsHills = "theWoodlandsHills",
  TowneLake = "towneLake",
  TravisPark = "travisPark",
  ValleyRanch = "valleyRanch",
  VillagesOfNorthpointe = "villagesOfNorthpointe",
  VintageTrailAtRiverstone = "vintageTrailAtRiverstone",
  VistaOfGreatwood = "vistaOfGreatwood",
  WalkersStation = "walkersStation",
  WaterSEdge = "waterSEdge",
  WatersideAtRiverstone = "watersideAtRiverstone",
  WestRanch = "westRanch",
  WilkinsCrossing = "wilkinsCrossing",
  WilliamsGlen = "williamsGlen",
  WilliamsGrant = "williamsGrant",
  Woodforest = "woodforest",
  WoodlandsVillageCreeksidePark = "woodlandsVillageCreeksidePark",
  WoodsonsReserve = "woodsonsReserve",
  Woodstream = "woodstream",
  Woodtrace = "woodtrace",
  WrightsLanding = "wrightsLanding"
}

export const legalSubdivisionLabel = new Map<LegalSubdivision, string>([
  [LegalSubdivision.LagoMar, "77568 – Lago Mar"],
  [LegalSubdivision.AlcornBend, "ALCORN BEND"],
  [LegalSubdivision.AldenWoods, "ALDEN WOODS"],
  [LegalSubdivision.AlderTrails, "ALDER TRAILS"],
  [LegalSubdivision.Aliana, "ALIANA"],
  [LegalSubdivision.ArborLake, "Arbor Lake"],
  [LegalSubdivision.AuburnManorAtRiverstone, "AUBURN MANOR AT RIVERSTONE"],
  [LegalSubdivision.AudubonPark, "AUDUBON PARK"],
  [LegalSubdivision.AugustaPines, "AUGUSTA PINES"],
  [LegalSubdivision.AustinMeadow, "AUSTIN MEADOW"],
  [LegalSubdivision.AustinPark, "AUSTIN PARK"],
  [LegalSubdivision.AustinParkCourtyardHomes, "AUSTIN PARK COURTYARD HOMES"],
  [LegalSubdivision.AutumnRidge, "AUTUMN RIDGE"],
  [LegalSubdivision.AvalonGardensOfAvalon, "AVALON - GARDENS OF AVALON"],
  [LegalSubdivision.AvalonMeadowsOfAvalon, "AVALON - MEADOWS OF AVALON"],
  [LegalSubdivision.AvalonVillagesOfAvalon, "AVALON - VILLAGES OF AVALON"],
  [LegalSubdivision.AvalonWatersOfAvalon, "Avalon - WATERS OF AVALON"],
  [LegalSubdivision.AvalonAtRiverstone, "AVALON AT RIVERSTONE"],
  [LegalSubdivision.AvalonAtSpringGreen, "AVALON AT SPRING GREEN"],
  [
    LegalSubdivision.AvalonEnclaveAtWatersOfAvalon,
    "AVALON-ENCLAVE AT WATERS OF AVALON"
  ],
  [LegalSubdivision.BendersLanding, "BENDERS LANDING"],
  [LegalSubdivision.BlakelyBend, "BLAKELY BEND"],
  [LegalSubdivision.BrandonSPoint, "BRANDON'S POINT"],
  [LegalSubdivision.BrazoriaBendCtryClbEst, "BRAZORIA BEND CTRY CLB EST"],
  [LegalSubdivision.BrazosLakes, "BRAZOS LAKES"],
  [LegalSubdivision.BrazosLanding, "BRAZOS LANDING"],
  [LegalSubdivision.BrazosValley, "BRAZOS VALLEY"],
  [LegalSubdivision.Briarwood, "BRIARWOOD"],
  [LegalSubdivision.Bridgeland, "BRIDGELAND"],
  [LegalSubdivision.BridgelandLakelandHeights, "BRIDGELAND LAKELAND HEIGHTS"],
  [LegalSubdivision.Bridgewater, "BRIDGEWATER"],
  [LegalSubdivision.BrooksideAtRiverstone, "BROOKSIDE AT RIVERSTONE"],
  [LegalSubdivision.CambridgePark, "CAMBRIDGE PARK"],
  [LegalSubdivision.CaneIsland, "CANE ISLAND"],
  [LegalSubdivision.CanyonLakesWest, "CANYON LAKES WEST"],
  [LegalSubdivision.Chimneystone, "CHIMNEYSTONE"],
  [LegalSubdivision.CincoRanchNorthwest, "CINCO RANCH NORTHWEST"],
  [LegalSubdivision.CincoRanchSouthwest, "CINCO RANCH SOUTHWEST"],
  [LegalSubdivision.CincoRanchWest, "CINCO RANCH WEST"],
  [LegalSubdivision.CityPark, "CITY PARK"],
  [LegalSubdivision.CityPlaza, "CITY PLAZA"],
  [LegalSubdivision.ClaytonsBend, "CLAYTONS BEND"],
  [LegalSubdivision.ColonyBend, "COLONY BEND"],
  [LegalSubdivision.ColonyCove, "COLONY COVE"],
  [LegalSubdivision.ColonyCreek, "COLONY CREEK"],
  [LegalSubdivision.ColonyGrant, "COLONY GRANT"],
  [LegalSubdivision.ColonyMeadows, "COLONY MEADOWS"],
  [LegalSubdivision.ColonyOaks, "COLONY OAKS"],
  [LegalSubdivision.ColonyPark, "COLONY PARK"],
  [LegalSubdivision.ColonyWoods, "COLONY WOODS"],
  [LegalSubdivision.CommonwealthEstates, "COMMONWEALTH ESTATES"],
  [LegalSubdivision.CommonwealthPark, "COMMONWEALTH PARK"],
  [LegalSubdivision.CreeksidePlace, "CREEKSIDE PLACE"],
  [
    LegalSubdivision.CreekstoneVillageAtRiverstone,
    "CREEKSTONE VILLAGE AT RIVERSTONE"
  ],
  [LegalSubdivision.CrescentLakes, "CRESCENT LAKES"],
  [LegalSubdivision.CrescentsOnTheGreen, "CRESCENTS ON THE GREEN"],
  [LegalSubdivision.CrossCreekRanch, "CROSS CREEK RANCH"],
  [LegalSubdivision.CrossingCoveAtRiverstone, "CROSSING COVE AT RIVERSTONE"],
  [LegalSubdivision.CypressCreekLakes, "CYPRESS CREEK LAKES"],
  [LegalSubdivision.Edgewater, "EDGEWATER"],
  [LegalSubdivision.EdgewoodAtRiverstone, "EDGEWOOD AT RIVERSTONE"],
  [LegalSubdivision.Elyson, "ELYSON"],
  [LegalSubdivision.Firethorne, "FIRETHORNE"],
  [LegalSubdivision.FirstColonyMud5, "FIRST COLONY MUD #5"],
  [LegalSubdivision.FostersGreen, "FOSTERS GREEN"],
  [LegalSubdivision.Fulbrook, "FULBROOK"],
  [LegalSubdivision.FulbrookCreeks, "FULBROOK CREEKS"],
  [LegalSubdivision.Fulshear, "FULSHEAR"],
  [LegalSubdivision.GrandCayHarbour, "GRAND CAY HARBOUR"],
  [LegalSubdivision.GrandMissionEstates, "GRAND MISSION ESTATES"],
  [LegalSubdivision.GrandVista, "GRAND VISTA"],
  [LegalSubdivision.GrantsLake, "GRANTS LAKE"],
  [LegalSubdivision.GrantsLakePatioHomes, "GRANTS LAKE PATIO HOMES"],
  [LegalSubdivision.GrantsLakeTempos, "GRANTS LAKE TEMPOS"],
  [LegalSubdivision.GreatwoodArbor, "GREATWOOD ARBOR"],
  [LegalSubdivision.GreatwoodB170, "GREATWOOD B-170"],
  [LegalSubdivision.GreatwoodBend, "GREATWOOD BEND"],
  [LegalSubdivision.GreatwoodBrooksMill, "GREATWOOD BROOKS MILL"],
  [LegalSubdivision.GreatwoodCharlestonEstates, "GREATWOOD CHARLESTON ESTATES"],
  [LegalSubdivision.GreatwoodCrossing, "GREATWOOD CROSSING"],
  [LegalSubdivision.GreatwoodEstates, "GREATWOOD ESTATES"],
  [LegalSubdivision.GreatwoodFairview, "GREATWOOD FAIRVIEW"],
  [LegalSubdivision.GreatwoodForest, "GREATWOOD FOREST"],
  [LegalSubdivision.GreatwoodGlen, "GREATWOOD GLEN"],
  [LegalSubdivision.GreatwoodGreen, "GREATWOOD GREEN"],
  [LegalSubdivision.GreatwoodHighlandPark, "GREATWOOD HIGHLAND PARK"],
  [LegalSubdivision.GreatwoodKnoll, "GREATWOOD KNOLL"],
  [LegalSubdivision.GreatwoodManor, "GREATWOOD MANOR"],
  [LegalSubdivision.GreatwoodShores, "GREATWOOD SHORES"],
  [LegalSubdivision.GreatwoodSpringfield, "GREATWOOD SPRINGFIELD"],
  [LegalSubdivision.GreatwoodStonebridge, "GREATWOOD STONEBRIDGE"],
  [LegalSubdivision.GreatwoodT270, "GREATWOOD T270"],
  [LegalSubdivision.GreatwoodTerrace, "GREATWOOD TERRACE"],
  [LegalSubdivision.GreatwoodTract, "Greatwood Tract"],
  [LegalSubdivision.GreatwoodTrails, "GREATWOOD TRAILS"],
  [LegalSubdivision.GreatwoodTuscanyPlace, "GREATWOOD TUSCANY PLACE"],
  [LegalSubdivision.GreatwoodVillage, "GREATWOOD VILLAGE"],
  [LegalSubdivision.GreatwoodWoodCreek, "GREATWOOD WOOD CREEK"],
  [LegalSubdivision.GreatwoodWoodhaven, "GREATWOOD WOODHAVEN"],
  [LegalSubdivision.Harmony, "HARMONY"],
  [LegalSubdivision.HarpersPreserve, "HARPERS PRESERVE"],
  [
    LegalSubdivision.HartfordLandingAtRiverstone,
    "HARTFORD LANDING AT RIVERSTONE"
  ],
  [LegalSubdivision.HarringtonTrails, "HARRINGTON TRAILS"],
  [LegalSubdivision.HighMeadows, "HIGH MEADOWS"],
  [LegalSubdivision.HuntersCreek, "HUNTERS CREEK"],
  [LegalSubdivision.HuntersGlen, "HUNTERS GLEN"],
  [LegalSubdivision.ImperialOaks, "IMPERIAL OAKS"],
  [LegalSubdivision.Jordan, "JORDAN"],
  [LegalSubdivision.JordanRanch, "JORDAN RANCH"],
  [LegalSubdivision.JordanSub, "JORDAN SUB"],
  [LegalSubdivision.Katy, "KATY"],
  [LegalSubdivision.KatyCreekRanch, "Katy Creek Ranch"],
  [LegalSubdivision.KensingtonAtRiverstone, "KENSINGTON AT RIVERSTONE"],
  [LegalSubdivision.KingwoodAtRoyalBrook, "KINGWOOD AT ROYAL BROOK"],
  [LegalSubdivision.LakePointe, "LAKE POINTE"],
  [LegalSubdivision.Lakefield, "LAKEFIELD"],
  [LegalSubdivision.LakesAtCreekside, "LAKES AT CREEKSIDE"],
  [LegalSubdivision.LakesAtMasonPark, "LAKES AT MASON PARK"],
  [LegalSubdivision.LakesOfAustinPark, "LAKES OF AUSTIN PARK"],
  [LegalSubdivision.LandingAtGrantsLake, "LANDING AT GRANTS LAKE"],
  [LegalSubdivision.LaurelGlen, "LAUREL GLEN"],
  [LegalSubdivision.LexingtonColony, "LEXINGTON COLONY"],
  [LegalSubdivision.LexingtonMeadows, "LEXINGTON MEADOWS"],
  [LegalSubdivision.LongMeadowFarms, "LONG MEADOW FARMS"],
  [LegalSubdivision.LongPointWoods, "LONG POINT WOODS"],
  [LegalSubdivision.MaranathaFarms, "MARANATHA FARMS"],
  [LegalSubdivision.Marbella, "MARBELLA"],
  [LegalSubdivision.MarbleBendAtRiverstone, "MARBLE BEND AT RIVERSTONE"],
  [LegalSubdivision.MeadowLakes, "MEADOW LAKES"],
  [LegalSubdivision.Meadowvale, "MEADOWVALE"],
  [LegalSubdivision.MeridianEstates, "MERIDIAN ESTATES"],
  [LegalSubdivision.MeridianParkAtRiverstone, "MERIDIAN PARK AT RIVERSTONE"],
  [LegalSubdivision.Meridiana, "MERIDIANA"],
  [LegalSubdivision.MillwoodAtRiverstone, "MILLWOOD AT RIVERSTONE"],
  [LegalSubdivision.Morningside, "MORNINGSIDE"],
  [LegalSubdivision.MostynManor, "MOSTYN MANOR"],
  [LegalSubdivision.NandinaAtRiverstone, "NANDINA AT RIVERSTONE"],
  [LegalSubdivision.NewTerritory, "NEW TERRITORY"],
  [LegalSubdivision.NewTerritoryParcelSf13, "NEW TERRITORY PARCEL SF-13"],
  [LegalSubdivision.NewTerritoryParcelSf2, "NEW TERRITORY PARCEL SF-2"],
  [LegalSubdivision.NewTerritoryParcelSf34, "NEW TERRITORY PARCEL SF-34"],
  [LegalSubdivision.NewTerritoryParcelSf35, "NEW TERRITORY PARCEL SF-35"],
  [LegalSubdivision.NewTerritoryParcelSf37, "NEW TERRITORY PARCEL SF-37"],
  [LegalSubdivision.NewTerritoryParcelSp2, "NEW TERRITORY PARCEL SP-2"],
  [LegalSubdivision.NewTerritoryParcelSp4, "NEW TERRITORY PARCEL SP-4"],
  [LegalSubdivision.NewTerritorySunsetCove, "NEW TERRITORY SUNSET COVE"],
  [LegalSubdivision.Northgrove, "NORTHGROVE"],
  [LegalSubdivision.Northpointe, "NORTHPOINTE"],
  [LegalSubdivision.Oakland, "OAKLAND"],
  [LegalSubdivision.OaksOfAlcorn, "OAKS OF ALCORN"],
  [LegalSubdivision.OliveHillAtRiverstone, "OLIVE HILL AT RIVERSTONE"],
  [LegalSubdivision.Other, "OTHER"],
  [LegalSubdivision.Oxford, "OXFORD"],
  [LegalSubdivision.ParkwayTerrace, "Parkway Terrace"],
  [LegalSubdivision.PointRoyale, "POINT ROYALE"],
  [LegalSubdivision.Pomona, "Pomona"],
  [LegalSubdivision.ProvidenceAtRiverstone, "PROVIDENCE AT RIVERSTONE"],
  [LegalSubdivision.RiverForest, "RIVER FOREST"],
  [LegalSubdivision.Riverpark, "RIVERPARK"],
  [LegalSubdivision.RiverparkWest, "RIVERPARK WEST"],
  [LegalSubdivision.RiversEdge, "RIVERS EDGE"],
  [LegalSubdivision.Riverstone, "RIVERSTONE"],
  [LegalSubdivision.RobinsonLanding, "ROBINSON LANDING"],
  [LegalSubdivision.RoyalOaksCourt, "ROYAL OAKS COURT"],
  [LegalSubdivision.SandersGlenAtRiverstone, "SANDERS GLEN AT RIVERSTONE"],
  [LegalSubdivision.SandersRidge, "SANDERS RIDGE"],
  [LegalSubdivision.SenovaAtRiverstone, "SENOVA AT RIVERSTONE"],
  [LegalSubdivision.SettlersGrove, "SETTLERS GROVE"],
  [LegalSubdivision.SettlersPark, "SETTLERS PARK"],
  [LegalSubdivision.SevenOaks, "SEVEN OAKS"],
  [LegalSubdivision.SevenOaksTH, "SEVEN OAKS T/H"],
  [LegalSubdivision.ShadowGlenAtRiverstone, "SHADOW GLEN AT RIVERSTONE"],
  [LegalSubdivision.SherwoodForestGlen, "SHERWOOD FOREST GLEN"],
  [LegalSubdivision.SiennaPlantation, "SIENNA PLANTATION"],
  [LegalSubdivision.SilverGroveAtRiverstone, "SILVER GROVE AT RIVERSTONE"],
  [LegalSubdivision.SilverRanch, "SILVER RANCH"],
  [LegalSubdivision.SomersetGreen, "SOMERSET GREEN"],
  [LegalSubdivision.Sommerall, "SOMMERALL"],
  [LegalSubdivision.SommerallPark, "SOMMERALL PARK"],
  [LegalSubdivision.SpencersGlen, "SPENCERS GLEN"],
  [LegalSubdivision.SpringBranchEstates, "SPRING BRANCH ESTATES"],
  [LegalSubdivision.SpringwoodsVillage, "SPRINGWOODS VILLAGE"],
  [LegalSubdivision.StablewoodFarms, "STABLEWOOD FARMS"],
  [LegalSubdivision.StephensGrant, "STEPHENS GRANT"],
  [LegalSubdivision.SterlingCreek, "STERLING CREEK"],
  [LegalSubdivision.Stillwater, "STILLWATER"],
  [LegalSubdivision.Stonehaven, "STONEHAVEN"],
  [LegalSubdivision.SummerLakes, "SUMMER LAKES"],
  [LegalSubdivision.Summerhill, "SUMMERHILL"],
  [LegalSubdivision.SuttonForest, "SUTTON FOREST"],
  [LegalSubdivision.SuttonPark, "SUTTON PARK"],
  [LegalSubdivision.Sweetbriar, "SWEETBRIAR"],
  [LegalSubdivision.SweetbriarAtRiverstone, "SWEETBRIAR AT RIVERSTONE"],
  [LegalSubdivision.Sweetwater, "SWEETWATER"],
  [LegalSubdivision.SweetwaterCourtTownhomes, "SWEETWATER COURT TOWNHOMES"],
  [LegalSubdivision.SweetwaterEstates, "SWEETWATER ESTATES"],
  [LegalSubdivision.SweetwaterTownhomes, "SWEETWATER TOWNHOMES"],
  [LegalSubdivision.Tara, "Tara "],
  [LegalSubdivision.Telfair, "TELFAIR"],
  [LegalSubdivision.TexanaPlantation, "TEXANA PLANTATION"],
  [LegalSubdivision.TheCityPlazaAtTownSquare, "THE CITY PLAZA AT TOWN SQUARE"],
  [LegalSubdivision.TheCrossingAtRiverstone, "THE CROSSING AT RIVERSTONE"],
  [LegalSubdivision.TheEnclaveAtGreatwood, "THE ENCLAVE AT GREATWOOD"],
  [LegalSubdivision.TheGroves, "THE GROVES"],
  [LegalSubdivision.TheHighlands, "THE HIGHLANDS"],
  [LegalSubdivision.TheLakes, "THE LAKES"],
  [LegalSubdivision.TheLandingAtGreatwood, "THE LANDING AT GREATWOOD"],
  [LegalSubdivision.TheOrchardAtRiverstone, "THE ORCHARD AT RIVERSTONE"],
  [LegalSubdivision.TheReserveAtRiverstone, "THE RESERVE AT RIVERSTONE"],
  [LegalSubdivision.TheTerraceAtRiverstone, "THE TERRACE AT RIVERSTONE"],
  [LegalSubdivision.TheTownsOfGrantsLake, "THE TOWNS OF GRANTS LAKE"],
  [LegalSubdivision.TheWoodlandsHills, "THE WOODLANDS HILLS"],
  [LegalSubdivision.TowneLake, "TOWNE LAKE"],
  [LegalSubdivision.TravisPark, "TRAVIS PARK"],
  [LegalSubdivision.ValleyRanch, "VALLEY RANCH"],
  [LegalSubdivision.VillagesOfNorthpointe, "VILLAGES OF NORTHPOINTE"],
  [LegalSubdivision.VintageTrailAtRiverstone, "VINTAGE TRAIL AT RIVERSTONE"],
  [LegalSubdivision.VistaOfGreatwood, "VISTA OF GREATWOOD"],
  [LegalSubdivision.WalkersStation, "WALKERS STATION"],
  [LegalSubdivision.WaterSEdge, "Water's Edge"],
  [LegalSubdivision.WatersideAtRiverstone, "WATERSIDE AT RIVERSTONE"],
  [LegalSubdivision.WestRanch, "WEST RANCH"],
  [LegalSubdivision.WilkinsCrossing, "WILKINS CROSSING"],
  [LegalSubdivision.WilliamsGlen, "WILLIAMS GLEN"],
  [LegalSubdivision.WilliamsGrant, "WILLIAMS GRANT"],
  [LegalSubdivision.Woodforest, "WOODFOREST"],
  [
    LegalSubdivision.WoodlandsVillageCreeksidePark,
    "WOODLANDS VILLAGE CREEKSIDE PARK"
  ],
  [LegalSubdivision.WoodsonsReserve, "Woodsons Reserve"],
  [LegalSubdivision.Woodstream, "WOODSTREAM"],
  [LegalSubdivision.Woodtrace, "WOODTRACE"],
  [LegalSubdivision.WrightsLanding, "WRIGHTS LANDING"]
]);
