import React, { Fragment } from "react";
import {
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from "@mui/material";

import {
  FormContext,
  FormContextActions,
  getOptionValueFromRule,
  formContextReducer,
  FormMode,
  Checkbox
} from "@homesusa/form";
import { AuthCompanyContext, useHasRole } from "@homesusa/auth";
import {
  GridTable,
  GridProvider,
  GridToolbar,
  FetchData,
  GridResponse,
  Columns
} from "@homesusa/grid-table";
import { getDate } from "@homesusa/core";
import { SubmitButton } from "@homesusa/layout";
import { Format } from "@homesusa/core";
import { BillActionTypeLabel, BillActionType } from "core/enums";
import { BillFilter } from "../interfaces/filters";
import { BillingReport as IBillingReport } from "../interfaces";
import { BillingReportFilters } from "./billing-report-filters.component";
import { ReportsContext } from "../contexts";
import { useCreateInvoice } from "../hooks/reports/use-create-invoice";

type columnReport = Columns<IBillingReport>;

export function BillingReport(): JSX.Element {
  const { isMlsAdministrator } = useHasRole();
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const [showModal, setShowModal] = React.useState(false);
  const handleCloseModal = (): void => setShowModal(false);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const getInitialState = (): BillFilter => {
    const fromDate = new Date();
    fromDate.setDate(1);
    const toDate = new Date();
    return Object.assign({
      from: fromDate,
      to: toDate,
      actionType: BillActionType.NewListing
    });
  };

  const {
    services: { getBillingReport },
    marketRules
  } = React.useContext(ReportsContext);
  const [formState, formDispatch] = React.useReducer<
    (state: BillFilter, action: FormContextActions) => BillFilter
  >(formContextReducer, getInitialState());
  const { currentCompany } = React.useContext(AuthCompanyContext);

  const [data, setData] = React.useState<GridResponse<IBillingReport>>({
    data: [],
    total: 0
  });

  const fetchData = async ({
    pageSize,
    pageIndex,
    sortBy,
    globalFilter
  }: FetchData): Promise<void> => {
    if (!formState.companyId) {
      return;
    }

    return getBillingReport({
      ...formState,
      take: pageSize,
      skip: pageIndex,
      sortBy,
      searchBy: globalFilter
    }).then((response) => {
      setData(response);
      setSelectedIds([]);
      setIsAllSelected(false);
    });
  };

  const fetchDataForDownloading = async (): Promise<IBillingReport[]> => {
    return getBillingReport({
      ...formState,
      take: data.total,
      skip: 0
    }).then((response) => {
      setSelectedIds([]);
      return response.data;
    });
  };

  const checkRow = (id: string, value: boolean): void => {
    setSelectedIds((prevSelectedIds) => {
      if (!value && prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((itemId) => itemId !== id);
      } else if (value && !prevSelectedIds.includes(id)) {
        return [...prevSelectedIds, id];
      }
      return prevSelectedIds;
    });
  };

  const checkAllRows = (): void => {
    if (isAllSelected) {
      setSelectedIds([]);
    } else {
      const newIds = data.data.map((item) => item.id);
      setSelectedIds(newIds);
    }

    setIsAllSelected(!isAllSelected);
  };

  const onGenerateInvoice = useCreateInvoice(
    selectedIds,
    currentMarket,
    getDate(formState.from),
    getDate(formState.to)
  );

  const onSumbit = async (): Promise<void> => {
    await onGenerateInvoice();
    await getBillingReport({
      ...formState,
      take: data.total,
      skip: 0
    }).then((response) => {
      setData(response);
      setSelectedIds([]);
    });
    handleCloseModal();
  };

  const columns: columnReport = React.useMemo(() => {
    return [
      ...(isMlsAdministrator
        ? [
            {
              Header: "Selection",
              accessor: (data: IBillingReport): JSX.Element => (
                <div>
                  <Checkbox
                    defaultValue={false}
                    value={selectedIds.includes(data.id)}
                    onChange={(value: boolean): void =>
                      checkRow(data.id, value)
                    }
                  />
                </div>
              ),
              id: "selector",
              disableSortBy: true
            }
          ]
        : []),
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1
      },
      {
        Header: "Builder",
        accessor: "ownerName"
      },
      {
        Header: "MLS #",
        accessor: "mlsNumber"
      },
      {
        Header: "Address",
        accessor: (data: IBillingReport): string =>
          `${data.streetNum} ${data.streetName}`,
        id: "streetNum"
      },
      {
        Header: "Subdivision",
        accessor: "subdivision"
      },
      {
        Header: "Zip",
        accessor: "zipCode"
      },
      {
        Header: "List Status",
        accessor: (data: IBillingReport): string =>
          getOptionValueFromRule(marketRules.mlsStatus, data.mlsStatus),
        id: "mlsStatus"
      },
      {
        Header: "List Date",
        accessor: (data: IBillingReport): string =>
          Format.DateTime(data.listDate),
        id: "listDate"
      },
      {
        Header: "Action Type",
        accessor: (data: IBillingReport): string =>
          data.publishType
            ? (BillActionTypeLabel.get(data.publishType) ?? "")
            : "publishType",
        id: "publishType"
      },
      {
        Header: "Submitted By",
        accessor: "createdBy"
      },
      {
        Header: "Created Status",
        accessor: (data: IBillingReport): string =>
          getOptionValueFromRule(marketRules.mlsStatus, data.publishStatus),
        id: "publishStatus"
      },
      {
        Header: "Submitted On",
        accessor: (data: IBillingReport): string =>
          Format.DateTime(data.sysCreatedOn),
        id: "sysCreatedOn"
      },
      {
        Header: "List Fee",
        accessor: (data: IBillingReport): string => Format.Money(data.listFee)
      }
    ];
  }, [selectedIds, isMlsAdministrator, isAllSelected, data]);

  if (!currentCompany) {
    return (
      <Alert severity="warning">
        Select a company from filter at very top, far right side
      </Alert>
    );
  }

  return (
    <Fragment>
      <FormContext.Provider
        value={{
          formState: formState,
          formDispatch,
          formMode: FormMode.Update,
          formRules: {},
          otherProps: {}
        }}
      >
        <GridProvider
          options={{
            columns,
            data: data?.data,
            showHeaderTooltip: true
          }}
          totalRows={data?.total}
          hidePagination={true}
        >
          <BillingReportFilters onFetch={fetchData} />
          {isMlsAdministrator && (
            <Grid container>
              <Grid item xs={1}>
                <Button
                  onClick={checkAllRows}
                  disabled={!isMlsAdministrator || data.data.length <= 0}
                >
                  {isAllSelected ? "Unselect All" : "Select All"}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={(): void => setShowModal(true)}
                  disabled={!selectedIds || selectedIds.length <= 0}
                >
                  Generate Invoice
                </Button>
              </Grid>
            </Grid>
          )}
          <GridToolbar hidePageSize>
            <GridToolbar.ExportButtons
              onClick={fetchDataForDownloading}
              fileName="billing-report"
            />
            <GridToolbar.SearchFilter />
          </GridToolbar>
          <Box mt={2} />
          <GridTable />
        </GridProvider>
      </FormContext.Provider>
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <p>
            This action will generate the invoice to Quickbooks. Would you like
            to continue?.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>No</Button>
          <SubmitButton onClick={onSumbit}>Yes</SubmitButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
