import React from "react";

import { FieldRulesProps } from "@homesusa/form";
import { DownloaderService, QuicklisterService } from "../interfaces/services";
import { ListFilter } from "../interfaces/filters";

export const ReportsContext = React.createContext<{
  services: DownloaderService & QuicklisterService;
  reportFilter: ListFilter;
  setReportFilter: (listFilter: ListFilter) => void;
  marketRules: {
    city: FieldRulesProps;
    occupancy?: FieldRulesProps;
    mlsStatus: FieldRulesProps;
  };
}>(Object.assign({}));
