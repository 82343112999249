export enum ElementarySchool {
  Academy = "academy",
  AdaMaeFaubion = "adaMaeFaubion",
  Akin = "akin",
  Allan = "allan",
  Allison = "allison",
  AlmaBrewerStrawn = "almaBrewerStrawn",
  AndersonMill = "andersonMill",
  Andrews = "andrews",
  AnniePurl = "anniePurl",
  ArlonRSeayInt = "arlonRSeayInt",
  AveryParkArea = "averyParkArea",
  Bagdad = "bagdad",
  Baldwin = "baldwin",
  Ball = "ball",
  Baranoff = "baranoff",
  Barrington = "barrington",
  Barron = "barron",
  Bartlett = "bartlett",
  BartonCreek = "bartonCreek",
  BartonHills = "bartonHills",
  Baty = "baty",
  BearCreek = "bearCreek",
  Becker = "becker",
  BeeCave = "beeCave",
  Bellaire = "bellaire",
  BenjaminDocKerleyElementary = "benjaminDocKerleyElementary",
  BenMilam = "benMilam",
  Hart = "hart",
  Casey = "casey",
  Bertram = "bertram",
  BethuneEarlyChildhoodCenter = "bethuneEarlyChildhoodCenter",
  BillBrown = "billBrown",
  BillBurden = "billBurden",
  BlacklandPrairie = "blacklandPrairie",
  Blackshear = "blackshear",
  BlakeManor = "blakeManor",
  Blanco = "blanco",
  BlancoVistaElem = "blancoVistaElem",
  Blanton = "blanton",
  Blazier = "blazier",
  BlockHouse = "blockHouse",
  Bluebonnet = "bluebonnet",
  BluebonnetTrail = "bluebonnetTrail",
  BonhamPark = "bonhamPark",
  BookerTWashington = "bookerTWashington",
  Boone = "boone",
  JacobsWell = "jacobsWell",
  Bowie = "bowie",
  Brentwood = "brentwood",
  BridgePoint = "bridgePoint",
  Brooke = "brooke",
  Brookhaven = "brookhaven",
  Brookhollow = "brookhollow",
  Brown = "brown",
  BrownPrimary = "brownPrimary",
  BrucevilleEddy = "brucevilleEddy",
  BrucevilleEddyInt = "brucevilleEddyInt",
  BrushyCreek = "brushyCreek",
  BrykerWoods = "brykerWoods",
  Buckholts = "buckholts",
  Buda = "buda",
  BudaPrimary = "budaPrimary",
  Burnet = "burnet",
  CactusRanch = "cactusRanch",
  Caldwell = "caldwell",
  CaldwellHeights = "caldwellHeights",
  Camacho = "camacho",
  Cameron = "cameron",
  CaminoReal = "caminoReal",
  Campbell = "campbell",
  CanyonCreek = "canyonCreek",
  CarlSchurz = "carlSchurz",
  CarpenterElementary = "carpenterElementary",
  CarpenterHill = "carpenterHill",
  Carver = "carver",
  CarverKindergarten = "carverKindergarten",
  Casis = "casis",
  Cater = "cater",
  CCMason = "ccMason",
  CedarCreek = "cedarCreek",
  CedarValley = "cedarValley",
  ChandlerOaks = "chandlerOaks",
  CharlotteCox = "charlotteCox",
  Cherokee = "cherokee",
  ChisholmTrail = "chisholmTrail",
  CiboloCreek = "ciboloCreek",
  CiboloValley = "ciboloValley",
  Clarke = "clarke",
  ClaudeBerkman = "claudeBerkman",
  Clayton = "clayton",
  ClearCreek = "clearCreek",
  ClearFork = "clearFork",
  ClearSpring = "clearSpring",
  CliftonPark = "cliftonPark",
  Colt = "colt",
  Comal = "comal",
  Cook = "cook",
  Copperfield = "copperfield",
  CottonwoodCreek = "cottonwoodCreek",
  CountyLine = "countyLine",
  Coupland = "coupland",
  Cowan = "cowan",
  CRClementsHollieParsons = "crClementsHollieParsons",
  Creedmoor = "creedmoor",
  Crockett = "crockett",
  CueroIntermediate = "cueroIntermediate",
  Cunningham = "cunningham",
  Curington = "curington",
  Cypress = "cypress",
  CypressSprings = "cypressSprings",
  Davis = "davis",
  Dawson = "dawson",
  Dearing = "dearing",
  Decker = "decker",
  DeepWood = "deepWood",
  DeerCreek = "deerCreek",
  DelcoPrimary = "delcoPrimary",
  DellPickett = "dellPickett",
  DelValle = "delValle",
  Dessau = "dessau",
  Dezavala = "dezavala",
  DimeBox = "dimeBox",
  Doss = "doss",
  DoubleFileTrail = "doubleFileTrail",
  DrippingSprings = "drippingSprings",
  Duncan = "duncan",
  Eanes = "eanes",
  EastWard = "eastWard",
  EdnaBighamMays = "ednaBighamMays",
  ElementaryDAEP = "elementaryDAEP",
  Elgin = "elgin",
  ElmGrove = "elmGrove",
  Emile = "emile",
  England = "england",
  Evant = "evant",
  EvenStar = "evenStar",
  Fabra = "fabra",
  FairOaksRanch = "fairOaksRanch",
  FairviewMissJewell = "fairviewMissJewell",
  Fayetteville = "fayetteville",
  FernBluff = "fernBluff",
  Flatonia = "flatonia",
  Florence = "florence",
  FM306Area = "fM306Area",
  ForestCreek = "forestCreek",
  ForestNorth = "forestNorth",
  ForestTrail = "forestTrail",
  Fowler = "fowler",
  Fredericksburg = "fredericksburg",
  FredericksburgPrimary = "fredericksburgPrimary",
  Freiheit = "freiheit",
  French = "french",
  Frost = "frost",
  Galindo = "galindo",
  GardenRidge = "gardenRidge",
  Gatesville = "gatesville",
  GatesvillePri = "gatesvillePri",
  Gattis = "gattis",
  Gause = "gause",
  Giddings = "giddings",
  Gilbert = "gilbert",
  Goldthwaite = "goldthwaite",
  Gonzales = "gonzales",
  GonzalesEastAvenuePrimary = "gonzalesEastAvenuePrimary",
  GonzalesEAvePrimary = "gonzalesEAvePrimary",
  GonzalesPrimary = "gonzalesPrimary",
  GoodwinFrazier = "goodwinFrazier",
  GoodwinPrimary = "goodwinPrimary",
  Govalle = "govalle",
  Graham = "graham",
  GrandviewHills = "grandviewHills",
  Granger = "granger",
  GreatOaks = "greatOaks",
  Green = "green",
  GreenValley = "greenValley",
  GuerreroThompson = "guerreroThompson",
  Gullett = "gullett",
  HannaSprings = "hannaSprings",
  HarkerHeights = "harkerHeights",
  Harper = "harper",
  Harris = "harris",
  HayBranch = "hayBranch",
  Haynes = "haynes",
  Hearne = "hearne",
  HectorPGarcia = "hectorPGarcia",
  Hemphill = "hemphill",
  Hermes = "hermes",
  Hernandez = "hernandez",
  Herrington = "herrington",
  HettieHalstead = "hettieHalstead",
  HighPoint = "highPoint",
  HighlandLake = "highlandLake",
  HighlandPark = "highlandPark",
  Hill = "hill",
  Hillcrest = "hillcrest",
  HoffmanLane = "hoffmanLane",
  Holland = "holland",
  HornsbyDunlap = "hornsbyDunlap",
  HouseCreek = "houseCreek",
  Houston = "houston",
  HowardNorman = "howardNorman",
  Hunt = "hunt",
  Hutto = "hutto",
  HuttoPrimary = "huttoPrimary",
  Hwy46Area = "hwy46Area",
  Iduma = "iduma",
  Igo = "igo",
  IndianSprings = "indianSprings",
  IraCrossJr = "iraCrossJr",
  Jarrell = "jarrell",
  JarrellInt = "jarrellInt",
  Jefferson = "jefferson",
  JeffersonAve = "jeffersonAve",
  JamesEMitchell = "jamesEMitchell",
  JimPlain = "jimPlain",
  JLWilliamsLovettLedger = "jlWilliamsLovettLedger",
  JoAnnFord = "joAnnFord",
  JoeLeeJohnson = "joeLeeJohnson",
  JoeMPirtle = "joeMPirtle",
  JohnASippel = "johnASippel",
  JohnsonRanch = "johnsonRanch",
  Jollyville = "jollyville",
  Jordan = "jordan",
  Joslin = "joslin",
  JuanSeguinPreK = "juanSeguinPreK",
  Karrer = "karrer",
  KathyCaraway = "kathyCaraway",
  Kendall = "kendall",
  KennedyPowell = "kennedyPowell",
  Kiker = "kiker",
  KinderRanch = "kinderRanch",
  KleinRoad = "kleinRoad",
  KlineWhitis = "klineWhitis",
  Knowles = "knowles",
  Kocurek = "kocurek",
  Koennecke = "koennecke",
  Kyle = "kyle",
  Lagos = "lagos",
  LagoVista = "lagoVista",
  LakePointe = "lakePointe",
  LakeTravis = "lakeTravis",
  Lakeway = "lakeway",
  Lakewood = "lakewood",
  Lamar = "lamar",
  Langford = "langford",
  Larkspur = "larkspur",
  LauraWelchBush = "lauraWelchBush",
  LaurelMountain = "laurelMountain",
  LaVernia = "laVernia",
  LaVerniaInt = "laVerniaInt",
  LaVerniaPri = "laVerniaPri",
  Lee = "lee",
  LeonardShanklin = "leonardShanklin",
  LeonHeight = "leonHeight",
  Lexington = "lexington",
  LibertyHill = "libertyHill",
  Linder = "linder",
  LiveOak = "liveOak",
  Llano = "llano",
  LoisFGiddens = "loisFGiddens",
  Lometa = "lometa",
  LoneStar = "loneStar",
  LostPines = "lostPines",
  Lott = "lott",
  LouineNoble = "louineNoble",
  LulingPrimary = "lulingPrimary",
  LyndonBJohnson = "lyndonBJohnson",
  MaeStevens = "maeStevens",
  MainStreetInt = "mainStreetInt",
  Manor = "manor",
  Maplewood = "maplewood",
  MarbleFalls = "marbleFalls",
  MarbleFallsPrimary = "marbleFallsPrimary",
  MartinWalker = "martinWalker",
  Mason = "mason",
  Mathews = "mathews",
  Maxdale = "maxdale",
  McBee = "mcBee",
  McDade = "mcDade",
  McDadeEl = "mcDadeEl",
  McQueeney = "mcQueeney",
  Meadows = "meadows",
  Memorial = "memorial",
  MemorialPrimary = "memorialPrimary",
  Menchaca = "menchaca",
  Mendez = "mendez",
  MeridithDunbar = "meridithDunbar",
  Metz = "metz",
  Specht = "specht",
  Milano = "milano",
  MillerHeights = "millerHeights",
  Mills = "mills",
  Mina = "mina",
  MontagueVillage = "montagueVillage",
  Moody = "moody",
  MoodyPreK = "moodyPreK",
  Morningside = "morningside",
  Mott = "mott",
  Moulton = "moulton",
  MountainValley = "mountainValley",
  MountainView = "mountainView",
  Mumford = "mumford",
  Murchison = "murchison",
  NotApplicable = "notApplicable",
  NadineJohnson = "nadineJohnson",
  NaomiPasemann = "naomiPasemann",
  Navarro = "navarro",
  NavarroIntermediate = "navarroIntermediate",
  LauraBNegley = "lauraBNegley",
  Neidig = "neidig",
  NewtonCollins = "newtonCollins",
  NewWillowRun = "newWillowRun",
  NeysaCallison = "neysaCallison",
  NixonSmiley = "nixonSmiley",
  Nolanville = "nolanville",
  NormaJPaschal = "normaJPaschal",
  NormaKrueger = "normaKrueger",
  NormaKruegerBertKarrerCampus = "normaKruegerBertKarrerCampus",
  Norman = "norman",
  North = "north",
  Northeast = "northeast",
  NorthOaks = "northOaks",
  Northside = "northside",
  Northwest = "northwest",
  OakCreek = "oakCreek",
  OakHill = "oakHill",
  OakMeadows = "oakMeadows",
  OakSprings = "oakSprings",
  Odom = "odom",
  OldTown = "oldTown",
  Ortega = "ortega",
  Other = "other",
  Overton = "overton",
  OvetaCulpHobby = "ovetaCulpHobby",
  Packsaddle = "packsaddle",
  Padron = "padron",
  Palm = "palm",
  Parkside = "parkside",
  ParmerLane = "parmerLane",
  PatCooper = "patCooper",
  Patlan = "patlan",
  PatsySommer = "patsySommer",
  Patton = "patton",
  PaulineNaumann = "paulineNaumann",
  Pease = "pease",
  Pebbles = "pebbles",
  PecanSprings = "pecanSprings",
  Perez = "perez",
  PershingPark = "pershingPark",
  Pflugerville = "pflugerville",
  Pickle = "pickle",
  Pillow = "pillow",
  PioneerCrossing = "pioneerCrossing",
  PleasantHill = "pleasantHill",
  PlumCreek = "plumCreek",
  PondSprings = "pondSprings",
  Popham = "popham",
  PrairieLea = "prairieLea",
  PresidentialMeadows = "presidentialMeadows",
  Primary = "primary",
  PurpleSage = "purpleSage",
  RaheBulverde = "raheBulverde",
  RalphPfluger = "ralphPfluger",
  RanchoSienna = "ranchoSienna",
  RayeAllen = "rayeAllen",
  RayElementary = "rayElementary",
  RayeMcCoy = "rayeMcCoy",
  RebeccaCreek = "rebeccaCreek",
  Redbud = "redbud",
  RedRock = "redRock",
  ReecesCreek = "reecesCreek",
  Reed = "reed",
  Reilly = "reilly",
  RoughHollow = "roughHollow",
  RichardECavazos = "richardECavazos",
  RichlandSprings = "richlandSprings",
  Ridgetop = "ridgetop",
  Riojas = "riojas",
  RiverOaks = "riverOaks",
  RiverPlace = "riverPlace",
  RiverRidge = "riverRidge",
  RJRichey = "rJRichey",
  Rockdale = "rockdale",
  Rodriguez = "rodriguez",
  Rogers = "rogers",
  RonaldReagan = "ronaldReagan",
  RoosterSprings = "roosterSprings",
  Rosebud = "rosebud",
  RoseGarden = "roseGarden",
  RosenwalkPrimary = "rosenwalkPrimary",
  RoundTopCarmine = "roundTopCarmine",
  RoweLane = "roweLane",
  RosalioTobiasIntSchool = "rosalioTobiasIntSchool",
  Rutledge = "rutledge",
  Saegert = "saegert",
  SaladoInt = "saladoInt",
  Sanchez = "sanchez",
  SanSaba = "sanSaba",
  SantaRita = "santaRita",
  Schertz = "schertz",
  Schulenburg = "schulenburg",
  ScienceHall = "scienceHall",
  Scott = "scott",
  Scudder = "scudder",
  Seele = "seele",
  SereneHills = "sereneHills",
  ShadowGlen = "shadowGlen",
  ShadyGrove = "shadyGrove",
  Shiner = "shiner",
  Sims = "sims",
  Skipcha = "skipcha",
  Smith = "smith",
  Smithville = "smithville",
  Southwest = "southwest",
  Sparta = "sparta",
  Spicewood = "spicewood",
  Springhill = "springhill",
  Star = "star",
  Startzville = "startzville",
  SteinerRanch = "steinerRanch",
  StElmo = "stElmo",
  StJoseph = "stJoseph",
  Stonewall = "stonewall",
  SugarLoaf = "sugarLoaf",
  Summitt = "summitt",
  SunsetValley = "sunsetValley",
  SusieFuentes = "susieFuentes",
  SycamoreSprings = "sycamoreSprings",
  Tarver = "tarver",
  Tarvin = "tarvin",
  TaylorCreek = "taylorCreek",
  Teravista = "teravista",
  TheVillage = "theVillage",
  THJohnson = "thJohnson",
  ThomasArnold = "thomasArnold",
  Thorndale = "thorndale",
  Thornton = "thornton",
  Thrall = "thrall",
  TimberRidge = "timberRidge",
  TimberwoodPark = "timberwoodPark",
  Timmerman = "timmerman",
  TimyBaranoff = "timyBaranoff",
  Tobias = "tobias",
  TomGreen = "tomGreen",
  Travis = "travis",
  TravisHts = "travisHts",
  Trimmier = "trimmier",
  Troy = "troy",
  Tyler = "tyler",
  UnionHill = "unionHill",
  UphausEarlyChildhood = "uphausEarlyChildhood",
  ValleyView = "valleyView",
  VenabelVillage = "venabelVillage",
  Veramendi = "veramendi",
  VeteransHill = "veteransHill",
  VicRobertson = "vicRobertson",
  VillageEL = "villageEL",
  VintageOaksArea = "vintageOaksArea",
  Vogel = "vogel",
  Voss = "voss",
  Waelder = "waelder",
  WalnutCreek = "walnutCreek",
  WalnutSprings = "walnutSprings",
  Watts = "watts",
  WebbPrimary = "webbPrimary",
  Weimar = "weimar",
  Weinert = "weinert",
  WellsBranch = "wellsBranch",
  WestCypressHills = "westCypressHills",
  WesternHills = "westernHills",
  Westside = "westside",
  WestWard = "westWard",
  Whitestone = "whitestone",
  Widen = "widen",
  Wiederstein = "wiederstein",
  Wieland = "wieland",
  WilliamJWinkley = "williamJWinkley",
  Williams = "williams",
  WilliamsonCoJJAEP = "williamsonCoJJAEP",
  WillowSprings = "willowSprings",
  Windermere = "windermere",
  WindermerePrimary = "windermerePrimary",
  Winn = "winn",
  WolfRanch = "wolfRanch",
  Wooldridge = "wooldridge",
  Wooten = "wooten",
  XeniaVoigt = "xeniaVoigt",
  Yoakum = "yoakum",
  YoakumInt = "yoakumInt",
  Zavala = "zavala",
  Zilker = "zilker"
}

export const ElementarySchoolLabel = new Map<ElementarySchool, string>([
  [ElementarySchool.Academy, "Academy"],
  [ElementarySchool.AdaMaeFaubion, "Ada Mae Faubion"],
  [ElementarySchool.Akin, "Akin"],
  [ElementarySchool.Allan, "Allan"],
  [ElementarySchool.Allison, "Allison"],
  [ElementarySchool.AlmaBrewerStrawn, "Alma Brewer Strawn"],
  [ElementarySchool.AndersonMill, "Anderson Mill"],
  [ElementarySchool.Andrews, "Andrews"],
  [ElementarySchool.AnniePurl, "Annie Purl"],
  [ElementarySchool.ArlonRSeayInt, "Arlon R Seay Int"],
  [ElementarySchool.AveryParkArea, "Avery Park Area"],
  [ElementarySchool.Bagdad, "Bagdad"],
  [ElementarySchool.Baldwin, "Baldwin"],
  [ElementarySchool.Ball, "Ball"],
  [ElementarySchool.Baranoff, "Baranoff"],
  [ElementarySchool.Barrington, "Barrington"],
  [ElementarySchool.Barron, "Barron"],
  [ElementarySchool.Bartlett, "Bartlett"],
  [ElementarySchool.BartonCreek, "Barton Creek"],
  [ElementarySchool.BartonHills, "Barton Hills"],
  [ElementarySchool.Baty, "Baty"],
  [ElementarySchool.BearCreek, "Bear Creek"],
  [ElementarySchool.Becker, "Becker"],
  [ElementarySchool.BeeCave, "Bee Cave"],
  [ElementarySchool.Bellaire, "Bellaire"],
  [
    ElementarySchool.BenjaminDocKerleyElementary,
    "Benjamin Doc Kerley Elementary"
  ],
  [ElementarySchool.BenMilam, "Ben Milam"],
  [ElementarySchool.Hart, "Hart"],
  [ElementarySchool.Casey, "Casey"],
  [ElementarySchool.Bertram, "Bertram"],
  [
    ElementarySchool.BethuneEarlyChildhoodCenter,
    "Bethune Early Childhood Center"
  ],
  [ElementarySchool.BillBrown, "Bill Brown"],
  [ElementarySchool.BillBurden, "Bill Burden"],
  [ElementarySchool.BlacklandPrairie, "Blackland Prairie"],
  [ElementarySchool.Blackshear, "Blackshear"],
  [ElementarySchool.BlakeManor, "Blake Manor"],
  [ElementarySchool.Blanco, "Blanco"],
  [ElementarySchool.BlancoVistaElem, "Blanco Vista Elem"],
  [ElementarySchool.Blanton, "Blanton"],
  [ElementarySchool.Blazier, "Blazier"],
  [ElementarySchool.BlockHouse, "Block House"],
  [ElementarySchool.Bluebonnet, "Bluebonnet"],
  [ElementarySchool.BluebonnetTrail, "Bluebonnet Trail"],
  [ElementarySchool.BonhamPark, "Bonham Park"],
  [ElementarySchool.BookerTWashington, "Booker T Washington"],
  [ElementarySchool.Boone, "Boone"],
  [ElementarySchool.JacobsWell, "Jacobs Well"],
  [ElementarySchool.Bowie, "Bowie"],
  [ElementarySchool.Brentwood, "Brentwood"],
  [ElementarySchool.BridgePoint, "Bridge Point"],
  [ElementarySchool.Brooke, "Brooke"],
  [ElementarySchool.Brookhaven, "Brookhaven"],
  [ElementarySchool.Brookhollow, "Brookhollow"],
  [ElementarySchool.Brown, "Brown"],
  [ElementarySchool.BrownPrimary, "Brown Primary"],
  [ElementarySchool.BrucevilleEddy, "Bruceville-Eddy"],
  [ElementarySchool.BrucevilleEddyInt, "Bruceville-Eddy Int"],
  [ElementarySchool.BrushyCreek, "Brushy Creek"],
  [ElementarySchool.BrykerWoods, "Bryker Woods"],
  [ElementarySchool.Buckholts, "Buckholts"],
  [ElementarySchool.Buda, "Buda"],
  [ElementarySchool.BudaPrimary, "Buda Primary"],
  [ElementarySchool.Burnet, "Burnet"],
  [ElementarySchool.CactusRanch, "Cactus Ranch"],
  [ElementarySchool.Caldwell, "Caldwell"],
  [ElementarySchool.CaldwellHeights, "Caldwell Heights"],
  [ElementarySchool.Camacho, "Camacho"],
  [ElementarySchool.Cameron, "Cameron"],
  [ElementarySchool.CaminoReal, "Camino Real"],
  [ElementarySchool.Campbell, "Campbell"],
  [ElementarySchool.CanyonCreek, "Canyon Creek"],
  [ElementarySchool.CarlSchurz, "Carl Schurz"],
  [ElementarySchool.CarpenterElementary, "Carpenter Elementary"],
  [ElementarySchool.CarpenterHill, "Carpenter Hill"],
  [ElementarySchool.Carver, "Carver"],
  [ElementarySchool.CarverKindergarten, "Carver Kindergarten"],
  [ElementarySchool.Casis, "Casis"],
  [ElementarySchool.Cater, "Cater"],
  [ElementarySchool.CCMason, "CC Mason"],
  [ElementarySchool.CedarCreek, "Cedar Creek"],
  [ElementarySchool.CedarValley, "Cedar Valley"],
  [ElementarySchool.ChandlerOaks, "Chandler Oaks"],
  [ElementarySchool.CharlotteCox, "Charlotte Cox"],
  [ElementarySchool.Cherokee, "Cherokee"],
  [ElementarySchool.ChisholmTrail, "Chisholm Trail"],
  [ElementarySchool.CiboloCreek, "Cibolo Creek"],
  [ElementarySchool.CiboloValley, "Cibolo Valley"],
  [ElementarySchool.Clarke, "Clarke"],
  [ElementarySchool.ClaudeBerkman, "Claude Berkman"],
  [ElementarySchool.Clayton, "Clayton"],
  [ElementarySchool.ClearCreek, "Clear Creek"],
  [ElementarySchool.ClearFork, "Clear Fork"],
  [ElementarySchool.ClearSpring, "Clear Spring"],
  [ElementarySchool.CliftonPark, "Clifton Park"],
  [ElementarySchool.Colt, "Colt"],
  [ElementarySchool.Comal, "Comal"],
  [ElementarySchool.Cook, "Cook"],
  [ElementarySchool.Copperfield, "Copperfield"],
  [ElementarySchool.CottonwoodCreek, "Cottonwood Creek"],
  [ElementarySchool.CountyLine, "County Line"],
  [ElementarySchool.Coupland, "Coupland"],
  [ElementarySchool.Cowan, "Cowan"],
  [ElementarySchool.CRClementsHollieParsons, "C R Clements/Hollie Parsons"],
  [ElementarySchool.Creedmoor, "Creedmoor"],
  [ElementarySchool.Crockett, "Crockett"],
  [ElementarySchool.CueroIntermediate, "Cuero Intermediate"],
  [ElementarySchool.Cunningham, "Cunningham"],
  [ElementarySchool.Curington, "Curington"],
  [ElementarySchool.Cypress, "Cypress"],
  [ElementarySchool.CypressSprings, "Cypress Springs"],
  [ElementarySchool.Davis, "Davis"],
  [ElementarySchool.Dawson, "Dawson"],
  [ElementarySchool.Dearing, "Dearing"],
  [ElementarySchool.Decker, "Decker"],
  [ElementarySchool.DeepWood, "Deep Wood"],
  [ElementarySchool.DeerCreek, "Deer Creek"],
  [ElementarySchool.DelcoPrimary, "Delco Primary"],
  [ElementarySchool.DellPickett, "Dell Pickett"],
  [ElementarySchool.DelValle, "Del Valle"],
  [ElementarySchool.Dessau, "Dessau"],
  [ElementarySchool.Dezavala, "Dezavala"],
  [ElementarySchool.DimeBox, "Dime Box"],
  [ElementarySchool.Doss, "Doss"],
  [ElementarySchool.DoubleFileTrail, "Double File Trail"],
  [ElementarySchool.DrippingSprings, "Dripping Springs"],
  [ElementarySchool.Duncan, "Duncan"],
  [ElementarySchool.Eanes, "Eanes"],
  [ElementarySchool.EastWard, "East Ward"],
  [ElementarySchool.EdnaBighamMays, "Edna Bigham Mays"],
  [ElementarySchool.ElementaryDAEP, "Elementary DAEP"],
  [ElementarySchool.Elgin, "Elgin"],
  [ElementarySchool.ElmGrove, "Elm Grove"],
  [ElementarySchool.Emile, "Emile"],
  [ElementarySchool.England, "England"],
  [ElementarySchool.Evant, "Evant"],
  [ElementarySchool.EvenStar, "Even Star"],
  [ElementarySchool.Fabra, "Fabra"],
  [ElementarySchool.FairOaksRanch, "Fair Oaks Ranch"],
  [ElementarySchool.FairviewMissJewell, "Fairview/Miss Jewell"],
  [ElementarySchool.Fayetteville, "Fayetteville"],
  [ElementarySchool.FernBluff, "Fern Bluff"],
  [ElementarySchool.Flatonia, "Flatonia"],
  [ElementarySchool.Florence, "Florence"],
  [ElementarySchool.FM306Area, "FM 306 Area"],
  [ElementarySchool.ForestCreek, "Forest Creek"],
  [ElementarySchool.ForestNorth, "Forest North"],
  [ElementarySchool.ForestTrail, "Forest Trail"],
  [ElementarySchool.Fowler, "Fowler"],
  [ElementarySchool.Fredericksburg, "Fredericksburg"],
  [ElementarySchool.FredericksburgPrimary, "Fredericksburg Primary"],
  [ElementarySchool.Freiheit, "Freiheit"],
  [ElementarySchool.French, "French"],
  [ElementarySchool.Frost, "Frost"],
  [ElementarySchool.Galindo, "Galindo"],
  [ElementarySchool.GardenRidge, "Garden Ridge"],
  [ElementarySchool.Gatesville, "Gatesville"],
  [ElementarySchool.GatesvillePri, "Gatesville Pri"],
  [ElementarySchool.Gattis, "Gattis"],
  [ElementarySchool.Gause, "Gause"],
  [ElementarySchool.Giddings, "Giddings"],
  [ElementarySchool.Gilbert, "Gilbert"],
  [ElementarySchool.Goldthwaite, "Goldthwaite"],
  [ElementarySchool.Gonzales, "Gonzales"],
  [ElementarySchool.GonzalesEastAvenuePrimary, "Gonzales East Avenue Primary"],
  [ElementarySchool.GonzalesEAvePrimary, "Gonzales E Ave Primary"],
  [ElementarySchool.GonzalesPrimary, "Gonzales Primary"],
  [ElementarySchool.GoodwinFrazier, "Goodwin Frazier"],
  [ElementarySchool.GoodwinPrimary, "Goodwin Primary"],
  [ElementarySchool.Govalle, "Govalle"],
  [ElementarySchool.Graham, "Graham"],
  [ElementarySchool.GrandviewHills, "Grandview Hills"],
  [ElementarySchool.Granger, "Granger"],
  [ElementarySchool.GreatOaks, "Great Oaks"],
  [ElementarySchool.Green, "Green"],
  [ElementarySchool.GreenValley, "Green Valley"],
  [ElementarySchool.GuerreroThompson, "Guerrero Thompson"],
  [ElementarySchool.Gullett, "Gullett"],
  [ElementarySchool.HannaSprings, "Hanna Springs"],
  [ElementarySchool.HarkerHeights, "Harker Heights"],
  [ElementarySchool.Harper, "Harper"],
  [ElementarySchool.Harris, "Harris"],
  [ElementarySchool.HayBranch, "Hay Branch"],
  [ElementarySchool.Haynes, "Haynes"],
  [ElementarySchool.Hearne, "Hearne"],
  [ElementarySchool.HectorPGarcia, "Hector P Garcia"],
  [ElementarySchool.Hemphill, "Hemphill"],
  [ElementarySchool.Hermes, "Hermes"],
  [ElementarySchool.Hernandez, "Hernandez"],
  [ElementarySchool.Herrington, "Herrington"],
  [ElementarySchool.HettieHalstead, "Hettie Halstead"],
  [ElementarySchool.HighPoint, "High Point"],
  [ElementarySchool.HighlandLake, "Highland Lake"],
  [ElementarySchool.HighlandPark, "Highland Park"],
  [ElementarySchool.Hill, "Hill"],
  [ElementarySchool.Hillcrest, "Hillcrest"],
  [ElementarySchool.HoffmanLane, "Hoffman Lane"],
  [ElementarySchool.Holland, "Holland"],
  [ElementarySchool.HornsbyDunlap, "Hornsby-Dunlap"],
  [ElementarySchool.HouseCreek, "House Creek"],
  [ElementarySchool.Houston, "Houston"],
  [ElementarySchool.HowardNorman, "Howard Norman"],
  [ElementarySchool.Hunt, "Hunt"],
  [ElementarySchool.Hutto, "Hutto"],
  [ElementarySchool.HuttoPrimary, "Hutto Primary"],
  [ElementarySchool.Hwy46Area, "Hwy 46 Area"],
  [ElementarySchool.Iduma, "Iduma"],
  [ElementarySchool.IndianSprings, "Indian Springs"],
  [ElementarySchool.Igo, "Igo"],
  [ElementarySchool.IraCrossJr, "Ira Cross Jr"],
  [ElementarySchool.Jarrell, "Jarrell"],
  [ElementarySchool.JarrellInt, "Jarrell Int"],
  [ElementarySchool.Jefferson, "Jefferson"],
  [ElementarySchool.JeffersonAve, "Jefferson Ave"],
  [ElementarySchool.JamesEMitchell, "James E  Mitchell"],
  [ElementarySchool.JimPlain, "Jim Plain"],
  [ElementarySchool.JLWilliamsLovettLedger, "J L Williams/Lovett Ledger"],
  [ElementarySchool.JoAnnFord, "Jo Ann Ford"],
  [ElementarySchool.JoeLeeJohnson, "Joe Lee Johnson"],
  [ElementarySchool.JoeMPirtle, "Joe M Pirtle"],
  [ElementarySchool.JohnASippel, "John A Sippel"],
  [ElementarySchool.JohnsonRanch, "Johnson Ranch"],
  [ElementarySchool.Jollyville, "Jollyville"],
  [ElementarySchool.Jordan, "Jordan"],
  [ElementarySchool.Joslin, "Joslin"],
  [ElementarySchool.JuanSeguinPreK, "Juan Seguin Pre-K"],
  [ElementarySchool.Karrer, "Karrer"],
  [ElementarySchool.KathyCaraway, "Kathy Caraway"],
  [ElementarySchool.Kendall, "Kendall"],
  [ElementarySchool.KennedyPowell, "Kennedy-Powell"],
  [ElementarySchool.Kiker, "Kiker"],
  [ElementarySchool.KinderRanch, "Kinder Ranch"],
  [ElementarySchool.KleinRoad, "Klein Road"],
  [ElementarySchool.KlineWhitis, "Kline Whitis"],
  [ElementarySchool.Knowles, "Knowles"],
  [ElementarySchool.Kocurek, "Kocurek"],
  [ElementarySchool.Koennecke, "Koennecke"],
  [ElementarySchool.Kyle, "Kyle"],
  [ElementarySchool.Lagos, "Lagos"],
  [ElementarySchool.LagoVista, "Lago Vista"],
  [ElementarySchool.LakePointe, "Lake Pointe"],
  [ElementarySchool.LakeTravis, "Lake Travis"],
  [ElementarySchool.Lakeway, "Lakeway"],
  [ElementarySchool.Lakewood, "Lakewood"],
  [ElementarySchool.Lamar, "Lamar"],
  [ElementarySchool.Langford, "Langford"],
  [ElementarySchool.Larkspur, "Larkspur"],
  [ElementarySchool.LauraWelchBush, "Laura Welch Bush"],
  [ElementarySchool.LaurelMountain, "Laurel Mountain"],
  [ElementarySchool.LaVernia, "La Vernia"],
  [ElementarySchool.LaVerniaInt, "La Vernia Int"],
  [ElementarySchool.LaVerniaPri, "La Vernia Pri"],
  [ElementarySchool.Lee, "Lee"],
  [ElementarySchool.LeonardShanklin, "Leonard Shanklin"],
  [ElementarySchool.LeonHeight, "Leon Height"],
  [ElementarySchool.Lexington, "Lexington"],
  [ElementarySchool.LibertyHill, "Liberty Hill"],
  [ElementarySchool.Linder, "Linder"],
  [ElementarySchool.LiveOak, "Live Oak"],
  [ElementarySchool.Llano, "Llano"],
  [ElementarySchool.LoisFGiddens, "Lois F Giddens"],
  [ElementarySchool.Lometa, "Lometa"],
  [ElementarySchool.LoneStar, "Lone Star"],
  [ElementarySchool.LostPines, "Lost Pines"],
  [ElementarySchool.Lott, "Lott"],
  [ElementarySchool.LouineNoble, "Louine Noble"],
  [ElementarySchool.LulingPrimary, "Luling Primary"],
  [ElementarySchool.LyndonBJohnson, "Lyndon B Johnson"],
  [ElementarySchool.MaeStevens, "Mae Stevens"],
  [ElementarySchool.MainStreetInt, "Main Street Int"],
  [ElementarySchool.Manor, "Manor"],
  [ElementarySchool.Maplewood, "Maplewood"],
  [ElementarySchool.MarbleFalls, "Marble Falls"],
  [ElementarySchool.MarbleFallsPrimary, "Marble Falls Primary"],
  [ElementarySchool.MartinWalker, "Martin Walker"],
  [ElementarySchool.Mason, "Mason"],
  [ElementarySchool.Mathews, "Mathews"],
  [ElementarySchool.Maxdale, "Maxdale"],
  [ElementarySchool.McBee, "McBee"],
  [ElementarySchool.McDade, "McDade"],
  [ElementarySchool.McDadeEl, "McDade El"],
  [ElementarySchool.McQueeney, "McQueeney"],
  [ElementarySchool.Meadows, "Meadows"],
  [ElementarySchool.Memorial, "Memorial"],
  [ElementarySchool.MemorialPrimary, "Memorial Primary"],
  [ElementarySchool.Menchaca, "Menchaca"],
  [ElementarySchool.Mendez, "Mendez"],
  [ElementarySchool.MeridithDunbar, "Meridith-Dunbar"],
  [ElementarySchool.Metz, "Metz"],
  [ElementarySchool.Specht, "Specht"],
  [ElementarySchool.Milano, "Milano"],
  [ElementarySchool.MillerHeights, "Miller Heights"],
  [ElementarySchool.Mills, "Mills"],
  [ElementarySchool.Mina, "Mina"],
  [ElementarySchool.MontagueVillage, "Montague Village"],
  [ElementarySchool.Moody, "Moody"],
  [ElementarySchool.MoodyPreK, "Moody Pre-K"],
  [ElementarySchool.Morningside, "Morningside"],
  [ElementarySchool.Mott, "Mott"],
  [ElementarySchool.Moulton, "Moulton"],
  [ElementarySchool.MountainValley, "Mountain Valley"],
  [ElementarySchool.MountainView, "Mountain View"],
  [ElementarySchool.Mumford, "Mumford"],
  [ElementarySchool.Murchison, "Murchison"],
  [ElementarySchool.NotApplicable, "N/A"],
  [ElementarySchool.NadineJohnson, "Nadine Johnson"],
  [ElementarySchool.NaomiPasemann, "Naomi Pasemann"],
  [ElementarySchool.Navarro, "Navarro"],
  [ElementarySchool.NavarroIntermediate, "Navarro Intermediate"],
  [ElementarySchool.LauraBNegley, "Laura B Negley"],
  [ElementarySchool.Neidig, "Neidig"],
  [ElementarySchool.NewtonCollins, "Newton Collins"],
  [ElementarySchool.NewWillowRun, "New Willow Run"],
  [ElementarySchool.NeysaCallison, "Neysa Callison"],
  [ElementarySchool.NixonSmiley, "Nixon-Smiley"],
  [ElementarySchool.Nolanville, "Nolanville"],
  [ElementarySchool.NormaJPaschal, "Norma J Paschal"],
  [ElementarySchool.NormaKrueger, "Norma Krueger"],
  [
    ElementarySchool.NormaKruegerBertKarrerCampus,
    "Norma Krueger/Bert Karrer Campus"
  ],
  [ElementarySchool.Norman, "Norman"],
  [ElementarySchool.North, "North"],
  [ElementarySchool.Northeast, "Northeast"],
  [ElementarySchool.NorthOaks, "North Oaks"],
  [ElementarySchool.Northside, "Northside"],
  [ElementarySchool.Northwest, "Northwest"],
  [ElementarySchool.OakCreek, "Oak Creek"],
  [ElementarySchool.OakHill, "Oak Hill"],
  [ElementarySchool.OakMeadows, "Oak Meadows"],
  [ElementarySchool.OakSprings, "Oak Springs"],
  [ElementarySchool.Odom, "Odom"],
  [ElementarySchool.OldTown, "Old Town"],
  [ElementarySchool.Ortega, "Ortega"],
  [ElementarySchool.Other, "Other"],
  [ElementarySchool.Overton, "Overton"],
  [ElementarySchool.OvetaCulpHobby, "Oveta Culp Hobby"],
  [ElementarySchool.Packsaddle, "Packsaddle"],
  [ElementarySchool.Padron, "Padron"],
  [ElementarySchool.Palm, "Palm"],
  [ElementarySchool.Parkside, "Parkside"],
  [ElementarySchool.ParmerLane, "Parmer Lane"],
  [ElementarySchool.PatCooper, "Pat Cooper"],
  [ElementarySchool.Patlan, "Patlan"],
  [ElementarySchool.PatsySommer, "Patsy Sommer"],
  [ElementarySchool.Patton, "Patton"],
  [ElementarySchool.PaulineNaumann, "Pauline Naumann"],
  [ElementarySchool.Pease, "Pease"],
  [ElementarySchool.Pebbles, "Pebbles"],
  [ElementarySchool.PecanSprings, "Pecan Springs"],
  [ElementarySchool.Perez, "Perez"],
  [ElementarySchool.PershingPark, "Pershing Park"],
  [ElementarySchool.Pflugerville, "Pflugerville"],
  [ElementarySchool.Pickle, "Pickle"],
  [ElementarySchool.Pillow, "Pillow"],
  [ElementarySchool.PioneerCrossing, "Pioneer Crossing"],
  [ElementarySchool.PleasantHill, "Pleasant Hill (Leander ISD)"],
  [ElementarySchool.PlumCreek, "Plum Creek"],
  [ElementarySchool.PondSprings, "Pond Springs"],
  [ElementarySchool.Popham, "Popham"],
  [ElementarySchool.PrairieLea, "Prairie Lea"],
  [ElementarySchool.PresidentialMeadows, "Presidential Meadows"],
  [ElementarySchool.Primary, "Primary"],
  [ElementarySchool.PurpleSage, "Purple Sage"],
  [ElementarySchool.RaheBulverde, "Rahe Bulverde"],
  [ElementarySchool.RalphPfluger, "Ralph Pfluger"],
  [ElementarySchool.RanchoSienna, "Rancho Sienna"],
  [ElementarySchool.RayeAllen, "Raye-Allen"],
  [ElementarySchool.RayElementary, "Ray Elementary"],
  [ElementarySchool.RayeMcCoy, "Raye McCoy"],
  [ElementarySchool.RebeccaCreek, "Rebecca Creek"],
  [ElementarySchool.Redbud, "Redbud"],
  [ElementarySchool.RedRock, "Red Rock"],
  [ElementarySchool.ReecesCreek, "Reeces Creek"],
  [ElementarySchool.Reed, "Reed"],
  [ElementarySchool.Reilly, "Reilly"],
  [ElementarySchool.RoughHollow, "Rough Hollow"],
  [ElementarySchool.RichardECavazos, "Richard E Cavazos"],
  [ElementarySchool.RichlandSprings, "Richland Springs"],
  [ElementarySchool.Ridgetop, "Ridgetop"],
  [ElementarySchool.Riojas, "Riojas"],
  [ElementarySchool.RiverOaks, "River Oaks"],
  [ElementarySchool.RiverPlace, "River Place"],
  [ElementarySchool.RiverRidge, "River Ridge"],
  [ElementarySchool.RJRichey, "RJ Richey"],
  [ElementarySchool.Rockdale, "Rockdale"],
  [ElementarySchool.Rodriguez, "Rodriguez (San Marcos ISD)"],
  [ElementarySchool.Rogers, "Rogers"],
  [ElementarySchool.RonaldReagan, "Ronald Reagan"],
  [ElementarySchool.RoosterSprings, "Rooster Springs"],
  [ElementarySchool.Rosebud, "Rosebud"],
  [ElementarySchool.RoseGarden, "Rose Garden"],
  [ElementarySchool.RosenwalkPrimary, "Rosenwalk Primary"],
  [ElementarySchool.RoundTopCarmine, "Round Top-Carmine"],
  [ElementarySchool.RoweLane, "Rowe Lane"],
  [ElementarySchool.RosalioTobiasIntSchool, "Rosalio Tobias Int School"],
  [ElementarySchool.Rutledge, "Rutledge"],
  [ElementarySchool.Saegert, "Saegert"],
  [ElementarySchool.SaladoInt, "Salado Int"],
  [ElementarySchool.Sanchez, "Sanchez"],
  [ElementarySchool.SanSaba, "San Saba"],
  [ElementarySchool.SantaRita, "Santa Rita"],
  [ElementarySchool.Schertz, "Schertz"],
  [ElementarySchool.Schulenburg, "Schulenburg"],
  [ElementarySchool.ScienceHall, "Science Hall"],
  [ElementarySchool.Scott, "Scott"],
  [ElementarySchool.Scudder, "Scudder"],
  [ElementarySchool.Seele, "Seele"],
  [ElementarySchool.SereneHills, "Serene Hills"],
  [ElementarySchool.ShadowGlen, "ShadowGlen"],
  [ElementarySchool.ShadyGrove, "Shady Grove"],
  [ElementarySchool.Shiner, "Shiner"],
  [ElementarySchool.Sims, "Sims"],
  [ElementarySchool.Skipcha, "Skipcha"],
  [ElementarySchool.Smith, "Smith"],
  [ElementarySchool.Smithville, "Smithville"],
  [ElementarySchool.Southwest, "Southwest"],
  [ElementarySchool.Sparta, "Sparta"],
  [ElementarySchool.Spicewood, "Spicewood"],
  [ElementarySchool.Springhill, "Springhill"],
  [ElementarySchool.Star, "Star"],
  [ElementarySchool.Startzville, "Startzville"],
  [ElementarySchool.SteinerRanch, "Steiner Ranch"],
  [ElementarySchool.StElmo, "St Elmo"],
  [ElementarySchool.StJoseph, "St Joseph"],
  [ElementarySchool.Stonewall, "Stonewall"],
  [ElementarySchool.SugarLoaf, "Sugar Loaf"],
  [ElementarySchool.Summitt, "Summitt"],
  [ElementarySchool.SunsetValley, "Sunset Valley"],
  [ElementarySchool.SusieFuentes, "Susie Fuentes"],
  [ElementarySchool.SycamoreSprings, "Sycamore Springs"],
  [ElementarySchool.Tarver, "Tarver"],
  [ElementarySchool.Tarvin, "Tarvin"],
  [ElementarySchool.TaylorCreek, "Taylor Creek"],
  [ElementarySchool.Teravista, "Teravista"],
  [ElementarySchool.TheVillage, "The Village"],
  [ElementarySchool.THJohnson, "TH Johnson"],
  [ElementarySchool.ThomasArnold, "Thomas Arnold"],
  [ElementarySchool.Thorndale, "Thorndale"],
  [ElementarySchool.Thornton, "Thornton"],
  [ElementarySchool.Thrall, "Thrall"],
  [ElementarySchool.TimberRidge, "Timber Ridge"],
  [ElementarySchool.TimberwoodPark, "Timberwood Park"],
  [ElementarySchool.Timmerman, "Timmerman"],
  [ElementarySchool.TimyBaranoff, "Timy Baranoff"],
  [ElementarySchool.Tobias, "Tobias"],
  [ElementarySchool.TomGreen, "Tom Green"],
  [ElementarySchool.Travis, "Travis"],
  [ElementarySchool.TravisHts, "Travis Hts"],
  [ElementarySchool.Trimmier, "Trimmier"],
  [ElementarySchool.Troy, "Troy"],
  [ElementarySchool.Tyler, "Tyler"],
  [ElementarySchool.UnionHill, "Union Hill"],
  [ElementarySchool.UphausEarlyChildhood, "Uphaus Early Childhood"],
  [ElementarySchool.ValleyView, "Valley View"],
  [ElementarySchool.VenabelVillage, "Venabel Village"],
  [ElementarySchool.Veramendi, "Veramendi"],
  [ElementarySchool.VeteransHill, "Veterans Hill"],
  [ElementarySchool.VicRobertson, "Vic Robertson"],
  [ElementarySchool.VillageEL, "Village EL"],
  [ElementarySchool.VintageOaksArea, "Vintage Oaks Area"],
  [ElementarySchool.Vogel, "Vogel"],
  [ElementarySchool.Voss, "Voss"],
  [ElementarySchool.Waelder, "Waelder"],
  [ElementarySchool.WalnutCreek, "Walnut Creek"],
  [ElementarySchool.WalnutSprings, "Walnut Springs"],
  [ElementarySchool.Watts, "Watts"],
  [ElementarySchool.WebbPrimary, "Webb Primary"],
  [ElementarySchool.Weimar, "Weimar"],
  [ElementarySchool.Weinert, "Weinert"],
  [ElementarySchool.WellsBranch, "Wells Branch"],
  [ElementarySchool.WestCypressHills, "West Cypress Hills"],
  [ElementarySchool.WesternHills, "Western Hills"],
  [ElementarySchool.Westside, "Westside"],
  [ElementarySchool.WestWard, "West Ward"],
  [ElementarySchool.Whitestone, "Whitestone"],
  [ElementarySchool.Widen, "Widen"],
  [ElementarySchool.Wiederstein, "Wiederstein"],
  [ElementarySchool.Wieland, "Wieland"],
  [ElementarySchool.WilliamJWinkley, "William J Winkley"],
  [ElementarySchool.Williams, "Williams"],
  [ElementarySchool.WilliamsonCoJJAEP, "Williamson Co JJ AEP"],
  [ElementarySchool.WillowSprings, "Willow Springs"],
  [ElementarySchool.Windermere, "Windermere"],
  [ElementarySchool.WindermerePrimary, "Windermere Primary"],
  [ElementarySchool.Winn, "Winn"],
  [ElementarySchool.WolfRanch, "Wolf Ranch"],
  [ElementarySchool.Wooldridge, "Wooldridge"],
  [ElementarySchool.Wooten, "Wooten"],
  [ElementarySchool.XeniaVoigt, "Xenia Voigt"],
  [ElementarySchool.Yoakum, "Yoakum"],
  [ElementarySchool.YoakumInt, "Yoakum Int"],
  [ElementarySchool.Zavala, "Zavala"],
  [ElementarySchool.Zilker, "Zilker"]
]);
