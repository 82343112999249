import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { AuthCompanyContext } from "@homesusa/auth";
import { getMarketRules } from "markets/utils";
import ImportCommunityAlertService from "../services/imported-community-alerts.service";
import ImportPlanAlertService from "../services/imported-plan-alerts.service";
import ImportListingActionsService from "../services/import-listing-alerts.service";
import { getXmlListings } from "../services/xml-listing-alerts.service";
import { getJsonListings } from "../services/json-listing-alerts.service";
import {
  getAlert,
  getAlertsCount,
  markAsResolved
} from "../services/alerts.service";
import { DashboardContext } from "../context/dashboard";
import { getAlertsByType } from "modules/dashboard/services/xml-panel-alerts.service";

export const DashboardOutlet = (): JSX.Element => {
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const context = React.useMemo(() => {
    return {
      importServices: {
        ...ImportCommunityAlertService(currentMarket),
        ...ImportPlanAlertService(currentMarket),
        ...ImportListingActionsService(currentMarket)
      },
      jsonServices: {
        getListings: getJsonListings(currentMarket)
      },
      xmlServices: {
        getListings: getXmlListings(currentMarket),
        getAlertsByType: getAlertsByType
      },
      alertServices: {
        getAlert: getAlert(currentMarket),
        getAlertsCount: getAlertsCount(currentMarket),
        markAsResolved: markAsResolved(currentMarket)
      },
      marketRules: getMarketRules(currentMarket).formFields
    };
  }, [currentMarket]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <DashboardContext.Provider value={context}>
        <Outlet />
      </DashboardContext.Provider>
    </Suspense>
  );
};
