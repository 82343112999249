import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CanvasJSReact from "@canvasjs/react-charts";
import { useStyles } from "./gauge.styles";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const Gauge = ({
  total,
  value,
  legend,
  height = 160,
  width = 160
}: {
  total: number;
  value: number;
  legend: string;
  height?: number;
  width?: number;
}): JSX.Element => {
  const { palette } = useTheme();
  if (total === 0) total = 1;
  const classes = useStyles(height);

  const containerProps = {
    height: `${height}px`,
    width: `${width}px`
  };

  const color = React.useMemo(() => {
    const percent = (value / total) * 100;
    if (percent >= 33 && percent < 66) return palette.warning.main;
    if (percent >= 66) return palette.error.main;
    return palette.success.main;
  }, [value, total]);
  const options = {
    animationEnabled: true,
    data: [
      {
        type: "doughnut",
        radius: "90%",
        innerRadius: "80%",
        dataPoints: [
          {
            y: total,
            color: "transparent",
            toolTipContent: null
          },
          { y: value, color, toolTipContent: null },
          {
            y: total - value,
            color: "#DEDEDE",
            toolTipContent: null,
            highlightEnabled: false
          }
        ]
      }
    ]
  };

  return (
    <Box sx={classes.root}>
      <Typography sx={classes.total}>{value}</Typography>
      <Typography color="GrayText" sx={classes.legend}>
        {legend}
      </Typography>
      <CanvasJSChart options={options} containerProps={containerProps} />
    </Box>
  );
};
