import { AlertBaseFilter } from "../interfaces";
import axios from "axios";
import { AlertType } from "../enums";
import { GridResponse } from "@homesusa/grid-table";
import { XmlFile } from "../interfaces/alerts/xml";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export const getAlertsByType = async (
  params: AlertBaseFilter,
  alertType: AlertType
): Promise<GridResponse<XmlFile>> => {
  const response = await axios.get<GridResponse<XmlFile>>(
    `${gatewayUrl}/api/xml/alerts/${alertType}`,
    {
      params
    }
  );
  return response.data;
};
