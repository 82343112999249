import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules, zipCodeRule } from "@homesusa/form";
import {
  StreetDirection,
  StreetDirectionLabel,
  StreetType,
  StreetTypeLabel
} from "markets/ctx/enums";
import {
  stateRule,
  streetNameRule,
  streetNumberRule,
  unitNumberRules
} from "core/rules/common-rules";
import { countyRules, ctxCityRule } from "../../sections";

export const addressInfoRules: FormRules = {
  county: {
    ...countyRules,
    requiredToSubmit: true
  },
  subdivision: {
    label: "Subdivision"
  },
  streetNumber: streetNumberRule,
  streetName: streetNameRule,
  state: stateRule,
  zipCode: {
    ...zipCodeRule,
    required: true
  },
  city: {
    ...ctxCityRule,
    required: true
  },
  streetDirection: {
    label: "Street Direction",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetDirection),
      StreetDirectionLabel
    )
  },
  streetType: {
    label: "Street Type",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetType),
      StreetTypeLabel
    )
  },
  unitNumber: {
    ...unitNumberRules,
    requiredToSubmit: false,
    minLength: 1,
    maxLength: 10
  },
  lotNum: { label: "Lot", maxLength: 5 },
  block: {
    label: "Block",
    maxLength: 5
  }
};
