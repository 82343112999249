import React from "react";
import { QueryStats } from "@mui/icons-material";

import { Typography } from "@mui/material";
import { AnimatedContainer } from "@homesusa/core";

import CanvasJSReact from "@canvasjs/react-charts";

import { StockChartData } from "modules/dashboard/interfaces";
import { useStyles } from "./stock-chart.styles";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const StockChart = ({
  data,
  height = 450,
  width
}: {
  data: StockChartData[];
  height?: number;
  width?: number;
}): JSX.Element => {
  const classes = useStyles(height, width);
  const optionData = data.map(({ name, dataPoints }) => ({
    name,
    dataPoints,
    xValueFormatString: "DD MMM YYYY",
    showInLegend: true,
    type: "spline"
  }));

  const hasDataPoints = optionData.some(
    ({ dataPoints }) => dataPoints.length > 0
  );

  const options = {
    animationEnabled: true,
    legend: {
      fontFamily: "Open Sans",
      fontWeight: "normal"
    },
    toolTip: { shared: true },
    axisX: {
      valueFormatString: "DD MMM",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        color: "#a6a6a6"
      },
      labelFontColor: "#8791a3",
      lineColor: "#cecece",
      labelFontFamily: "Open Sans"
    },
    axisY: {
      gridThickness: 0.25,
      tickLength: 0,
      lineColor: "#cecece",
      labelFontColor: "#8791a3",
      labelFontFamily: "Open Sans"
    },
    data: optionData
  };

  const containerProps = {
    width: width ? `${width}px` : "100%",
    height: `${height}px`,
    margin: "auto"
  };

  if (!hasDataPoints)
    return (
      <AnimatedContainer sx={classes.noData}>
        <QueryStats sx={classes.icon} />
        <Typography variant="h2">No data available</Typography>
      </AnimatedContainer>
    );

  return <CanvasJSChart options={options} containerProps={containerProps} />;
};
