import { FormRules } from "@homesusa/form";
import { utilitiesRules } from "../../sections";

export const featuresInfoRules: FormRules = {
  ...utilitiesRules,
  propertyDescription: {
    label: "Property Description",
    requiredToSubmit: true,
    maxLength: 2000,
    rows: 5,
    minLength: 25,
    minLengthSuggestion: 100,
    helperText:
      "MLS number, Built by <BUILDER> and Completion Info will be added as a prefix to this field. DO NOT INCLUDE."
  }
};
